import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row, Table, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import { API_URL } from './utils/Constants/URLS'
import '../css/OrgContacts.css'
import '../css/common.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import PageHeader from './utils/PageHeader'
const ClientContact = () => {
  const [clientContact, setClientContact] = useState([])
  const [sortByValue, setSortByValue] = useState(null)
  const [totalPages, setTotalPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const token = localStorage.getItem('token')

  const getClientContactData = async () => {
    const url = `${API_URL}clients?page=${pageNumber}&limit=12&sortBy=${sortByValue}`
    const data = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const result = await data.json()
    setClientContact(result.data.docs)
    setTotalPages(result.data.totalPages)
  }

  useEffect(() => {
    getClientContactData()
  }, [sortByValue, pageNumber])

  return (
    <Fragment>
      <div className='w-100'>
        <PageHeader
          pageName='CLIENT CONTACT'
          breadcrump1='Home'
          breadcrump2='Client Contact'
          link='/dashboard'
        />
        <div className='main p-4'>
          <CardBody className='bg-white p-3'>
            <form>
              <Row>
                <Col lg={3} md={3} sm={6} xs={6}>
                  <div>
                    <input
                      className='form-control'
                      type='text'
                      // onChange={(e) => setSearchValue(e.target.value)}
                      placeholder='Search Here'
                    />
                  </div>
                </Col>
                <Col
                  xl={9}
                  lg={9}
                  md={9}
                  sm={6}
                  xs={6}
                  className='flex-wrap d-none d-md-block'
                >
                  <div className='float-right'>
                    <Link to='/add_client_contact'>
                      <button type='button' className='btn add-btn text-white mr-2'>
                        +Add Client
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
          <CardBody>
            <div className='table-responsive sm:overflow-auto sm:max-h-[800px] w-full  mb-3 '>
              <Table hover className='align-middle table-nowrap'>
                <thead className='table-light'>
                  <tr className='sticky left-0 top-0 z-20'>
                    <th>
                      <div className='flex items-center'>
                        Name
                        <svg
                          onClick={() =>
                            sortByValue === 'name'
                              ? setSortByValue(null)
                              : setSortByValue('name')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th>
                      <div className='flex items-center'>Edit</div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Contact Person
                        <svg
                          onClick={() =>
                            sortByValue === 'email'
                              ? setSortByValue(null)
                              : setSortByValue('email')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Designation
                        <svg
                          onClick={() =>
                            sortByValue === 'phoneNumber'
                              ? setSortByValue(null)
                              : setSortByValue('phoneNumber')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Phone Number
                        <svg
                          onClick={() =>
                            sortByValue === 'phoneNumber'
                              ? setSortByValue(null)
                              : setSortByValue('phoneNumber')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Email
                        <svg
                          onClick={() =>
                            sortByValue === 'phoneNumber'
                              ? setSortByValue(null)
                              : setSortByValue('phoneNumber')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {clientContact.map((clientDetails, clientIndex) => {
                    return (
                      <Fragment key={clientIndex}>
                        {clientDetails?.contactPersons?.map(
                          (cp, contactIndex) => {
                            return (
                              <tr key={contactIndex}>
                                {contactIndex === 0 && (
                                  // Only apply rowSpan for the first contact person of the client
                                  <>
                                    <td
                                      rowSpan={
                                        clientDetails.contactPersons.length
                                      }
                                    >
                                      <Link
                                        className='text-dark no-underline sticky left-0 z-10 px-4 py-2 border-b'
                                        to={`/client_profile/${clientDetails.id}`}
                                      >
                                        {clientDetails.companyName}
                                      </Link>
                                    </td>
                                    <td
                                      rowSpan={
                                        clientDetails.contactPersons.length
                                      }
                                    >
                                      <Link
                                        className='text-warning no-underline'
                                        to={`edit_client/${clientDetails.id}`}
                                      >
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                      </Link>
                                    </td>
                                  </>
                                )}
                                <td>{cp.name}</td>
                                <td>{cp.designation}</td>
                                <td>{cp.phoneNumber}</td>
                                <td>{cp.email}</td>
                              </tr>
                            )
                          }
                        )}
                      </Fragment>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardBody className='float-right'>
            <nav className='ml-5' aria-label='...'>
              <ul className='pagination'>
                <li className='page-item disabled'>
                  <Link onClick={() => setPageNumber(pageNumber - 1)} className='page-link text-primary' to='#'>
                    Previous
                  </Link>
                </li>
                {[...Array(totalPages)].map((_, index) => {
                  return (
                    <li className='page-item'>
                      <Link
                        onClick={() => setPageNumber(index + 1)}
                        className='page-link text-primary'
                        to='#'
                      >
                        {index + 1}
                      </Link>
                    </li>
                  )
                })}
                <li className='page-item'>
                  <Link onClick={() => setPageNumber(pageNumber + 1)} className='page-link text-primary' to='#'>
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </CardBody>
        </div>
      </div>
    </Fragment>
  )
}

export default ClientContact
