import React, { Fragment } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'

const ViewPipeline = () => {
    return (
        <Fragment>
            <Card>
                <CardHeader>Deals in Pipeline</CardHeader>
                <CardBody>
                    <table className='table table-striped'>
                        <thead>
                            <tr >
                                <th>Pipeline Name</th>
                                <th>Total Deal Value</th>
                                <th>No of Deals</th>
                                <th>Stages</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Update Platform</td>
                                <td>Rs. 500000</td>
                                <td>654</td>
                                <td>In Pipeline</td>
                            </tr>
                            <tr>
                                <td>Update Platform</td>
                                <td>Rs. 500000</td>
                                <td>654</td>
                                <td>In Pipeline</td>
                            </tr>
                            <tr>
                                <td>Update Platform</td>
                                <td>Rs. 500000</td>
                                <td>654</td>
                                <td>In Pipeline</td>
                            </tr>
                            <tr>
                                <td>Update Platform</td>
                                <td>Rs. 500000</td>
                                <td>654</td>
                                <td>In Pipeline</td>
                            </tr>
                            <tr>
                                <td>Update Platform</td>
                                <td>Rs. 500000</td>
                                <td>654</td>
                                <td>In Pipeline</td>
                            </tr>
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default ViewPipeline