import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row, Table, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { CSVLink } from 'react-csv'
import { AddEmpModal } from './Modals/AddEmpModal'
import EditEmpModal from './Modals/EditEmpModal'
import { API_URL } from './utils/Constants/URLS'
import '../css/style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import {
  faDownload,
  faList,
  faPenToSquare,
  faUpload
} from '@fortawesome/free-solid-svg-icons'
import PageHeader from './utils/PageHeader'
const OrgContact = () => {
  const [orgContact, setOrgContact] = useState([])
  const [businessLineList, setBusinessLineList] = useState([])
  const [businessLineId, setBusinessLineId] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [sortByValue, setSortByValue] = useState(null)
  const [totalPages, setTotalPages] = useState(1)
  const [showBlList, setShowBlList] = useState(false)
  const [finalSearchValue] = useDebounce(searchValue, 1000)
  const [modalData, setModalData] = useState(null)

  const token = localStorage.getItem('token')
  const userDetails = useSelector(store => store.user)

  useEffect(() => {
    getOrgContactData(pageNumber)
  }, [pageNumber, finalSearchValue, sortByValue, businessLineId])

  const getOrgContactData = async page_number => {
    const url = `${API_URL}users/org-contacts?page=${page_number}&limit=100&search=${finalSearchValue}&sortBy=${sortByValue}&businessLine=${businessLineId}`
    const data = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const result = await data.json()
    setOrgContact(result.data.docs)
    setTotalPages(result.data.totalPages)
  }

  const invokeEditEmpModal = (
    id,
    name,
    phone,
    email,
    role,
    businessArea,
    businessLine
  ) => {
    setModalData(null) // Reset modal data
    setTimeout(() => {
      setModalData({ id, name, phone, email, role, businessArea, businessLine })
    }, 0) // Slight delay to ensure React registers the state change
  }

  useEffect(() => {
    const fetchBusinessLines = async () => {
      const url = `${API_URL}business-lines?pagination=false`
      const data = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      const result = await data.json()
      setBusinessLineList(
        result.data.docs.map(item => ({
          value: item.id,
          label: item.name
        }))
      )
    }

    fetchBusinessLines()
  }, [token])

  return (
    <Fragment>
      <div className='w-100'>
        <PageHeader
          pageName='ORGANISATION CONTACT'
          breadcrump1='Home'
          breadcrump2='Organisation Contact'
          link='/dashboard'
        />
        <div className='main lg:p-6 xl:p-6 2xl:p-6 sm:p-3 md:p-3 '>
          <CardBody className='bg-white p-3'>
            <form>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <div className='mb-2'>
                    <input
                      className='form-control'
                      type='text'
                      onChange={e => setSearchValue(e.target.value)}
                      placeholder='Search Here'
                    />
                  </div>
                </Col>
                {userDetails.role !== 'executive' ? (
                  <Col lg={9} md={9} sm={12} xs={12} className='flex-wrap'>
                    <div className='lg:float-right xl:float-right 2xl:float-right flex'>
                      <div>
                        <button
                          type='button'
                          onClick={() => setShowBlList(!showBlList)}
                          className='btn filter-btn mr-2 text-white'
                        >
                          <FontAwesomeIcon className='mr-3' icon={faList} />{' '}
                          Filters
                        </button>
                      </div>
                      <div className='mr-2'>
                        <AddEmpModal />
                      </div>
                      <div>
                        <button
                          type='button'
                          className='btn download-btn mr-2 '
                        >
                          <CSVLink
                            className='mt-3 bg-transparent download-btn'
                            filename='organisation_contact.csv'
                            data={orgContact}
                          >
                            <FontAwesomeIcon className=' ' icon={faDownload} />
                          </CSVLink>
                        </button>
                      </div>
                      <div>
                        <button
                          type='button'
                          className='btn download-btn mr-2 '
                        >
                          <FontAwesomeIcon className='' icon={faUpload} />
                        </button>
                      </div>
                    </div>
                    {showBlList && (
                      <div className='mt-10 right-0 p-3  me-72 bg-white shadow-sm border absolute z-40 rounded'>
                        {businessLineList.map((data, index) => {
                          return (
                            <div class='form-check' key={index}>
                              <input
                                name='bl_filter'
                                className='form-check-input'
                                type='radio'
                                value={businessLineId}
                                onChange={() => setBusinessLineId(data?.value)}
                                id='bl_filter'
                              />
                              <label
                                className='form-check-label'
                                for='defaultCheck1'
                              >
                                {data?.label}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </form>
          </CardBody>
          <CardBody>
            <div className='table-responsive sm:overflow-auto sm:max-h-[400px] w-full  mb-3 '>
              <Table hover className='align-middle table-nowrap'>
                <thead className='table-light'>
                  <tr>
                    <th className='sticky left-0 top-0 px-4 z-20'>
                      <div className='flex items-center'>
                        Name
                        <svg
                          onClick={() =>
                            sortByValue === 'name'
                              ? setSortByValue(null)
                              : setSortByValue('name')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th className='sticky top-0 z-10'>
                      <div className='flex items-center'>
                        Email
                        <svg
                          onClick={() =>
                            sortByValue === 'email'
                              ? setSortByValue(null)
                              : setSortByValue('email')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th className='sticky top-0 z-10'>
                      <div className='flex items-center'>
                        Phone No
                        <svg
                          onClick={() =>
                            sortByValue === 'phoneNumber'
                              ? setSortByValue(null)
                              : setSortByValue('phoneNumber')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th className='sticky top-0 z-10'>
                      <div className='flex items-center'>
                        Role
                        <svg
                          onClick={() =>
                            sortByValue === 'role'
                              ? setSortByValue(null)
                              : setSortByValue('role')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th className='sticky top-0 z-10'>
                      <div className='flex items-center'>
                        Business Line
                        <svg
                          onClick={() =>
                            sortByValue === 'name'
                              ? setSortByValue(null)
                              : setSortByValue('name')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    {userDetails.role !== 'executive' ? (
                      <th className='sticky top-0 z-10'>Action</th>
                    ) : (
                      ''
                    )}
                  </tr>
                </thead>

                <tbody>
                  {orgContact.map((empDetails, index) => {
                    return (
                      <tr key={index}>
                        <td className='sticky left-0 z-10 bg-white px-4 py-2 border-b'>
                          {empDetails?.name}
                        </td>
                        <td>{empDetails?.email}</td>
                        <td>{empDetails?.phoneNumber}</td>
                        <td>
                          {empDetails?.role.charAt(0)?.toUpperCase() +
                            empDetails?.role?.slice(1)}
                        </td>
                        <td>
                          {empDetails?.businessLine &&
                          empDetails?.businessLine.name
                            ? empDetails?.businessLine?.name
                            : ''}
                        </td>
                        {userDetails.role !== 'executive' ? (
                          <td>
                            <FontAwesomeIcon
                              onClick={() =>
                                invokeEditEmpModal(
                                  empDetails?.id,
                                  empDetails?.name,
                                  empDetails?.phoneNumber,
                                  empDetails?.email,
                                  empDetails?.role,
                                  empDetails?.businessArea,
                                  empDetails?.businessLine
                                )
                              }
                              className='text-warning'
                              icon={faPenToSquare}
                            />
                          </td>
                        ) : (
                          ''
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardBody className='float-right'>
            <nav className='ml-5' aria-label='...'>
              <ul className='pagination'>
                <li className='page-item disabled'>
                  <Link className='page-link text-primary' to='#' tabindex='-1'>
                    Previous
                  </Link>
                </li>
                {[...Array(totalPages)].map((_, index) => {
                  return (
                    <li className='page-item'>
                      <Link
                        onClick={() => setPageNumber(index + 1)}
                        className='page-link text-primary'
                        to='#'
                      >
                        {index + 1}
                      </Link>
                    </li>
                  )
                })}
                <li className='page-item'>
                  <Link className='page-link text-primary' to='#'>
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </CardBody>
        </div>
        {modalData && (
          <EditEmpModal
            id={modalData?.id}
            show_modal={true}
            userName={modalData?.name}
            userEmail={modalData?.email}
            userPhone={modalData?.phone}
            userRole={modalData?.role}
            business_area={modalData?.businessArea || ''}
            business_line={[modalData?.businessLine || '']}
          />
        )}
      </div>
    </Fragment>
  )
}

export default OrgContact
