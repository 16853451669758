import React, { Fragment, useState, useRef } from 'react'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { Toast } from 'primereact/toast'
import Modal from 'react-bootstrap/Modal'
import { useNavigate, useParams } from 'react-router-dom'
import { API_URL } from '../../utils/Constants/URLS'

const WorkingPlatform = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const toast = useRef(null)
  const token = localStorage.getItem('token')
  const [modalMessage, setModalMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [differentEndUser, setDifferentEndUser] = useState(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [designation, setDesignation] = useState('')
  const [
    technicalAndCommercialDiscussionCompleted,
    setTechnicalAndCommercialDiscussionCompleted
  ] = useState(null)
  const [commercialProposalSubmitted, setCommercialProposalSubmitted] =
    useState(null)
  const [approvedFinanceDescription, setApprovedFinanceDescription] =
    useState('')
  const [installationVisitDone, setInstallationVisitDone] = useState(null)
  const [dateOfVisit, setDateOfVisit] = useState('')
  const [visitNotes, setVisitNotes] = useState('')
  const [
    firstRoundCommercialNegotiationStarted,
    setFirstRoundCommercialNegotiationStarted
  ] = useState(null)

  const handleCloseModal = () => {
    setShowSuccessModal(false)
    setShowDeclineModal(false)
    navigate('/view_leads')
  }

  console.log(differentEndUser);
  

  const handleSubmit = async (e, terminate = false) => {
    e.preventDefault()

    if (differentEndUser === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select End User different from customer or Not',
        life: 3000
      })
    } else if (differentEndUser === 'true' && name === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter End User Name',
        life: 3000
      })
    } else if (differentEndUser === 'true' && phoneNumber === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter End User Phone',
        life: 3000
      })
    } else if (differentEndUser === 'true' && designation === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter End User Designation',
        life: 3000
      })
    } else if (differentEndUser === 'true' && email === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter End User Email',
        life: 3000
      })
    } else if (technicalAndCommercialDiscussionCompleted === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail:
          'Please Select Technical and Commercial discussion completed or Not',
        life: 3000
      })
    } else if (commercialProposalSubmitted === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Commercial Proposal Submitted on Not',
        life: 3000
      })
    } else if (firstRoundCommercialNegotiationStarted === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail:
          'Please Select First Round of Commercial Negotiation Started or Not',
        life: 3000
      })
    } else if (approvedFinanceDescription === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Approved Finance Description',
        life: 3000
      })
    } else if (installationVisitDone === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Installation Visit Done or Not',
        life: 3000
      })
    } else if (installationVisitDone === 'true' && dateOfVisit === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the Date of Visit',
        life: 3000
      })
    } else if (installationVisitDone === 'true' && visitNotes === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the Visit Note',
        life: 3000
      })
    } else {
      try {
        const response = await fetch(`${API_URL}leads/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            workingPlatform: {
              differentEndUser,
              contactPerson: {
                name,
                email,
                phoneNumber,
                designation
              },
              technicalAndCommercialDiscussionCompleted,
              commercialProposalSubmitted,
              approvedFinanceDescription,
              installationVisitDone,
              dateOfVisit,
              visitNotes,
              firstRoundCommercialNegotiationStarted,
              status: 'done'
            }
          })
        })

        const res = await response.json()
        if (res.error === false) {
          setModalMessage('Working Platform Updated Successfully')
          setShowSuccessModal(true)
        } else {
          setModalMessage('Something Went Wrong')
          setShowDeclineModal(true)
        }
      } catch (error) {
        setModalMessage('Oops! Error Occur While Adding Data')
        setShowDeclineModal(true)
      }
    }
  }

  return (
    <Fragment>
      <div>
        <form className='row g-3 needs-validation' noValidate>
          <div className='row mt-3'>
            <div className='col-md-6'>
              <label for='name' className='form-label'>
                Is End User different from customer{' '}
                <span className='text-danger'>*</span>
              </label>
              <br />
              <div className='form-check form-check-inline'>
                <input
                  required
                  name='differentEndUser'
                  value={true}
                  onChange={e => setDifferentEndUser(e.target.value)}
                  className='form-check-input'
                  type='radio'
                  id='end-user'
                />
                <label className='form-check-label' for='inlineCheckbox1'>
                  Yes
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  required
                  name='differentEndUser'
                  value={false}
                  onChange={e => setDifferentEndUser(e.target.value)}
                  className='form-check-input'
                  type='radio'
                  id='end-user'
                />
                <label className='form-check-label' for='inlineCheckbox1'>
                  No
                </label>
              </div>
            </div>
          </div>
          {differentEndUser === 'true' ? (
            <div className='row mt-3'>
              <h5>End User Details</h5>
              <div className='col-md-6'>
                <label for='name' className='form-label'>
                  Name <span className='text-danger'>*</span>
                </label>
                <input
                  name='name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  type='text'
                  className='form-control'
                  data-section='contactPerson'
                />
              </div>
              <div className='col-md-6'>
                <label for='name' className='form-label'>
                  Mobile Number <span className='text-danger'>*</span>
                </label>
                <input
                  name='phoneNumber'
                  data-section='contactPerson'
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  type='number'
                  className='form-control'
                />
              </div>
              <div className='col-md-6'>
                <label for='name' className='form-label'>
                  Designation <span className='text-danger'>*</span>
                </label>
                <input
                  name='designation'
                  data-section='contactPerson'
                  value={designation}
                  onChange={e => setDesignation(e.target.value)}
                  type='text'
                  className='form-control'
                />
              </div>
              <div className='col-md-6'>
                <label for='name' className='form-label'>
                  Email <span className='text-danger'>*</span>
                </label>
                <input
                  name='email'
                  data-section='contactPerson'
                  type='email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className='form-control'
                />
              </div>
            </div>
          ) : (
            ''
          )}

          <div className='row mt-3'>
            <div className='col-md-7'>
              <label for='name' className='form-label'>
                Technical and Commercial discussion completed{' '}
                <span className='text-danger'>*</span>
              </label>
              <br />
              <div class='form-check form-check-inline'>
                <input
                  required
                  value={true}
                  onChange={e =>
                    setTechnicalAndCommercialDiscussionCompleted(e.target.value)
                  }
                  class='form-check-input'
                  type='radio'
                  name='technicalAndCommercialDiscussionCompleted'
                  id='discussion'
                />
                <label class='form-check-label' for='inlineCheckbox1'>
                  Yes
                </label>
              </div>
              <div class='form-check form-check-inline'>
                <input
                  required
                  value={false}
                  onChange={e =>
                    setTechnicalAndCommercialDiscussionCompleted(e.target.value)
                  }
                  class='form-check-input'
                  type='radio'
                  name='technicalAndCommercialDiscussionCompleted'
                  id='discussion'
                />
                <label class='form-check-label' for='inlineCheckbox1'>
                  No
                </label>
              </div>
            </div>
            <div className='col-md-5'>
              <label for='name' className='form-label'>
                Commercial Proposal Submitted<span className='text-danger'>*</span>
              </label>
              <br />
              <div class='form-check form-check-inline'>
                <input
                  required
                  value={true}
                  onChange={e => setCommercialProposalSubmitted(e.target.value)}
                  name='commercialProposalSubmitted'
                  class='form-check-input'
                  type='radio'
                  id='com-proposal'
                />
                <label class='form-check-label' for='inlineCheckbox1'>
                  Yes
                </label>
              </div>
              <div class='form-check form-check-inline'>
                <input
                  required
                  value={false}
                  onChange={e => setCommercialProposalSubmitted(e.target.value)}
                  class='form-check-input'
                  type='radio'
                  name='commercialProposalSubmitted'
                  id='com-proposal'
                />
                <label class='form-check-label' for='inlineCheckbox1'>
                  No
                </label>
              </div>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-7'>
              <label for='name' className='form-label'>
                First Round of Commercial negotiation started?{' '}
                <span className='text-danger'>*</span>
              </label>
              <br />
              <div class='form-check form-check-inline'>
                <input
                  required
                  value={true}
                  onChange={e =>
                    setFirstRoundCommercialNegotiationStarted(e.target.value)
                  }
                  class='form-check-input'
                  type='radio'
                  name='firstRoundCommercialNegotiationStarted'
                  id='com-negotiation'
                />
                <label class='form-check-label' for='inlineCheckbox1'>
                  Yes
                </label>
              </div>
              <div class='form-check form-check-inline'>
                <input
                  required
                  value={false}
                  onChange={e =>
                    setFirstRoundCommercialNegotiationStarted(e.target.value)
                  }
                  class='form-check-input'
                  type='radio'
                  name='firstRoundCommercialNegotiationStarted'
                  id='com-negotiation'
                />
                <label class='form-check-label' for='inlineCheckbox1'>
                  No
                </label>
              </div>
            </div>
            <div className='col-md-5'>
              <label for='name' className='form-label'>
                Approved Finance Description
                <span className='text-danger'>*</span>
              </label>
              <input
                name='approvedFinanceDescription'
                value={approvedFinanceDescription}
                onChange={e => setApprovedFinanceDescription(e.target.value)}
                type='text'
                className='form-control'
                id='approvedFinanceDescription'
                data-section='workingPlatform'
              />
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-6'>
              <label for='name' className='form-label'>
                Installation Visit Done? <span className='text-danger'>*</span>
              </label>
              <br />
              <div class='form-check form-check-inline'>
                <input
                  required
                  value={true}
                  onChange={e => setInstallationVisitDone(e.target.value)}
                  class='form-check-input'
                  type='radio'
                  name='installationVisitDone'
                  id='installation-visit-done'
                />
                <label class='form-check-label' for='inlineCheckbox1'>
                  Yes
                </label>
              </div>
              <div class='form-check form-check-inline'>
                <input
                  required
                  value={false}
                  onChange={e => setInstallationVisitDone(e.target.value)}
                  class='form-check-input'
                  type='radio'
                  name='installationVisitDone'
                  id='installation-visit-done'
                />
                <label class='form-check-label' for='inlineCheckbox1'>
                  No
                </label>
              </div>
            </div>

            {installationVisitDone === 'true' ? (
              <>
                <div className='col-md-6'>
                  <label for='name' className='form-label'>
                    Date and time of Visit{' '}
                    <span className='text-danger'>*</span>
                  </label>
                  <input
                    value={dateOfVisit}
                    onChange={e => setDateOfVisit(e.target.value)}
                    className='form-control'
                    type='date'
                    name='dateOfVisit'
                    id='Test_DatetimeLocal'
                  />
                </div>
                <div className='col-md-6'>
                  <label for='enquiry-details' className='form-label'>
                    Visit Note <span className='text-danger'>*</span>
                  </label>
                  <textarea
                    name='visitNote'
                    value={visitNotes}
                    onChange={e => setVisitNotes(e.target.value)}
                    type='text'
                    className='form-control'
                    id='enquiry-details'
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <div className='col-md-12 d-flex justify-content-between'>
            <button
              name='terminateLead'
              className='btn btn-danger'
              onClick={e => handleSubmit(e, true)}
              type='submit'
            >
              Terminate
            </button>
            <button
              onClick={handleSubmit}
              className='btn add-btn text-white'
              type='submit'
            >
              Next
            </button>
          </div>
        </form>
        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div class='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Good Job
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={showDeclineModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 fill-red-500 inline'
                viewBox='0 0 286.054 286.054'
              >
                <path
                  fill='#e2574c'
                  d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                  data-original='#e2574c'
                ></path>
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Error Occured
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Toast ref={toast} />
      </div>
    </Fragment>
  )
}

export default WorkingPlatform
