import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import React, { Fragment, useState } from 'react'
import '../../../css/AddClient.css'
import { Link } from 'react-router-dom'
import { API_URL } from '../../utils/Constants/URLS'
import { validate } from '../../utils/validate'
import IndianStates from '../../utils/Constants/IndianStateList'
import Select from 'react-select'
const AddClient = () => {
  const token = localStorage.getItem('token')
  let countAddress = 0
  let countContactPerson = 0
  const [isOpenAddress, setIsOpenAddress] = useState([])
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [formData, setFormData] = useState({
    companyName: '',
    address: [
      {
        addressNickName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        pincode: ''
      }
    ],
    phoneNumber: '',
    phoneNumberOptional: '',
    contactPersons: [
      {
        name: '',
        email: '',
        phoneNumber: '',
        phoneNumberOptional: '',
        designation: ''
      }
    ],
    organizationType: '',
    isGSTRegistered: true,
    gstNumber: '',
    createdBy: ''
  })

  const handleCloseModal = () => {
    setShowSuccessModal(false)
    setShowDeclineModal(false)
  }

  const handleInputChange = (input, index, field, type) => {
    // Determine if `input` is from a Select component (an object with a `value` property)
    const value =
      input && typeof input === 'object' && input.hasOwnProperty('value')
        ? input.value // Select component provides the `value` property
        : input.target.value // Standard input event

    if (type === 'address') {
      const updatedAddresses = [...formData.address]
      updatedAddresses[index][field] = value
      setFormData({ ...formData, address: updatedAddresses })
    } else if (type === 'contactPersons') {
      const updatedContacts = [...formData.contactPersons]
      updatedContacts[index][field] = value
      setFormData({ ...formData, contactPersons: updatedContacts })
    } else {
      setFormData({ ...formData, [field]: value })
    }
  }

  const handleAddAddress = () => {
    setFormData({
      ...formData,
      address: [
        ...formData.address,
        {
          addressNickName: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: '',
          pincode: ''
        }
      ]
    })
    setIsOpenAddress([...isOpenAddress, false])
  }

  const handleDeleteAddress = indexToDelete => {
    setFormData({
      ...formData,
      address: formData.address.filter((_, index) => index !== indexToDelete)
    })
  }

  const handleAddContactPerson = () => {
    setFormData({
      ...formData,
      contactPersons: [
        ...formData.contactPersons,
        {
          name: '',
          email: '',
          phoneNumber: '',
          phoneNumberOptional: '',
          designation: ''
        }
      ]
    })
  }

  const handleDeleteContactPerson = indexToDelete => {
    setFormData({
      ...formData,
      contactPersons: formData.contactPersons.filter(
        (_, index) => index !== indexToDelete
      )
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const message = validate(
      formData.gstNumber,
      formData.phoneNumber,
      formData.phoneNumberOptional
    )
    console.log(message)
    if (message === null) {
      try {
        const response = await fetch(`${API_URL}clients`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(formData)
        })
        const result = await response.json()
        if (result.error === false) {
          setShowSuccessModal(true)
        } else {
          setShowDeclineModal(true)
        }
      } catch (error) {
        setShowDeclineModal(true)
      }
    } else {
      alert(message)
    }
  }

  return (
    <Fragment>
      <div>
        <form
          onSubmit={handleSubmit}
          className='row g-3 needs-validation'
          novalidate
        >
          <div className='row mt-3 pb-3 border-bottom'>
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Company Name <span className='text-danger'>*</span>
              </label>
              <input
                value={formData.companyName}
                onChange={e => handleInputChange(e, null, 'companyName')}
                type='text'
                className='form-control'
                id='name'
                required
              />
              <div className='invalid-feedback'>
                Please enter the company name
              </div>
            </div>
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Phone <span className='text-danger'>*</span>
              </label>
              <input
                value={formData.phoneNumber}
                onChange={e => handleInputChange(e, null, 'phoneNumber')}
                type='number'
                className='form-control'
                id='name'
                required
              />
              <div className='invalid-feedback'>
                Please enter a valid phone number
              </div>
            </div>
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Alternate Phone
              </label>
              <input
                value={formData.phoneNumberOptional}
                onChange={e =>
                  handleInputChange(e, null, 'phoneNumberOptional')
                }
                type='number'
                className='form-control'
                id='name'
              />
            </div>
            <div className='col-md-4 mt-2'>
              <label for='name' className='form-label'>
                Organization Type <span className='text-danger'>*</span>
              </label>
              <select
                value={formData.organizationType}
                onChange={e => handleInputChange(e, null, 'organizationType')}
                className='form-select custom-select'
              >
                <option>Limited</option>
                <option>Private Limited</option>
                <option>LLP</option>
                <option>Govt</option>
                <option>Public Sector Unit</option>
                <option>UT</option>
                <option>Partnership</option>
                <option>Proprietorship</option>
              </select>
            </div>
            <div className='col-md-4 mt-2'>
              <label for='name' className='form-label'>
                Is GST Registered <span className='text-danger'>*</span>
              </label>
              <select
                value={formData.isGSTRegistered} // Display 'Yes' or 'No' based on the boolean value
                onChange={e =>
                  handleInputChange(e, null, 'isGSTRegistered', 'boolean')
                } // Use 'boolean' type to differentiate
                className='form-select'
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
            <div className='col-md-4 mt-2'>
              <label for='name' className='form-label'>
                GST Number <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                value={formData.gstNumber}
                onChange={e => handleInputChange(e, null, 'gstNumber')}
              />
            </div>
          </div>
          {formData.address.map((address, index) => (
            <div className='row mt-3' key={index}>
              <div className='d-flex justify-content-between'>
                <span>
                  <h6>
                    Add{' '}
                    {(countAddress = countAddress + 1) === 1
                      ? 'First'
                      : 'Second'}{' '}
                    Address
                  </h6>
                </span>
                {countAddress > 1 ? (
                  <span>
                    <div>
                      <Link className='text-dark no-underline'>
                        <FontAwesomeIcon
                          className='ml-1 text-danger'
                          onClick={() => handleDeleteAddress(index)}
                          icon={faTrashCan}
                        />
                      </Link>
                    </div>
                  </span>
                ) : (
                  ' '
                )}
              </div>

              <div className='col-md-4'>
                <label className='form-label'>
                  Country <span className='text-danger'>*</span>
                </label>
                <select
                  value={address.country}
                  onChange={e =>
                    handleInputChange(e, index, 'country', 'address')
                  }
                  className='form-select'
                >
                  <option>Select</option>
                  <option>India</option>
                  <option>Bangladesh</option>
                </select>
              </div>
              <div className='col-md-4'></div>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <label className='form-label'>
                  Address Type <span className='text-danger'>*</span>
                </label>
                <input
                  value={address.addressNickName}
                  onChange={e =>
                    handleInputChange(e, index, 'addressNickName', 'address')
                  }
                  type='text'
                  className='form-control'
                  placeholder='Eg: Head Office'
                />
              </div>
              <div className='col-md-4'>
                <label className='form-label'>
                  Address Line 1 <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={address.addressLine1}
                  onChange={e =>
                    handleInputChange(e, index, 'addressLine1', 'address')
                  }
                />
              </div>
              <div className='col-md-4'>
                <label className='form-label'>Address Line 2</label>
                <input
                  type='text'
                  className='form-control'
                  value={address.addressLine2}
                  onChange={e =>
                    handleInputChange(e, index, 'addressLine2', 'address')
                  }
                />
              </div>

              <div className='col-md-4 mt-2'>
                <label className='form-label'>
                  City <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={address.city}
                  onChange={e => handleInputChange(e, index, 'city', 'address')}
                />
              </div>
              <div className='col-md-4 mt-2'>
                <label className='form-label'>
                  State <span className='text-danger'>*</span>
                </label>

                {formData.address[index].country === 'India' ? (
                  <Select
                    value={IndianStates.find(
                      option => option.value === address.state
                    )}
                    onChange={selectedOption =>
                      handleInputChange(
                        selectedOption,
                        index,
                        'state',
                        'address'
                      )
                    }
                    options={IndianStates}
                  />
                ) : (
                  <input
                    type='text'
                    className='form-control'
                    value={address.state}
                    onChange={e =>
                      handleInputChange(e, index, 'state', 'address')
                    }
                  />
                )}
              </div>
              <div className='col-md-4 mt-2'>
                <label className='form-label'>
                  Pin: <span className='text-danger'>*</span>
                </label>
                <input
                  type='number'
                  className='form-control'
                  value={address.pincode}
                  onChange={e =>
                    handleInputChange(e, index, 'pincode', 'address')
                  }
                />
              </div>
            </div>
          ))}
          <div className='row mt-3'>
            <div className='col-md-4'>
              {countAddress <= 1 ? (
                <button
                  onClick={handleAddAddress}
                  type='button'
                  id='add_address'
                  className='btn add-btn  text-white'
                >
                  Add New Address
                </button>
              ) : (
                ' '
              )}
            </div>
          </div>
          {formData.contactPersons.map((person, index) => (
            <div className='row mt-3' key={index}>
              <div className='d-flex justify-content-between'>
                <span>
                  <h6>
                    Add{' '}
                    {(() => {
                      const ordinals = [
                        'First',
                        'Second',
                        'Third',
                        'Fourth',
                        'Fifth'
                      ]
                      return (
                        ordinals[
                          (countContactPerson = countContactPerson + 1) - 1
                        ] || countAddress
                      )
                    })()}{' '}
                    Contact Person
                  </h6>
                  {countContactPerson > 1 ? (
                    <span>
                      <div>
                        <Link className='text-dark no-underline'>
                          <FontAwesomeIcon
                            className='ml-1 text-danger'
                            onClick={() => handleDeleteContactPerson(index)}
                            icon={faTrashCan}
                          />
                        </Link>
                      </div>
                    </span>
                  ) : (
                    ' '
                  )}
                </span>
              </div>

              <div className='col-md-6'>
                <label className='form-label'>
                  Name <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={person.name}
                  onChange={e =>
                    handleInputChange(e, index, 'name', 'contactPersons')
                  }
                />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  Email <span className='text-danger'>*</span>
                </label>
                <input
                  type='email'
                  className='form-control'
                  value={person.email}
                  onChange={e =>
                    handleInputChange(e, index, 'email', 'contactPersons')
                  }
                />
              </div>
              <div className='col-md-4 mt-3'>
                <label className='form-label'>
                  Phone Number <span className='text-danger'>*</span>
                </label>
                <input
                  type='number'
                  className='form-control'
                  value={person.phoneNumber}
                  onChange={e =>
                    handleInputChange(e, index, 'phoneNumber', 'contactPersons')
                  }
                />
              </div>
              <div className='col-md-4 mt-3'>
                <label className='form-label'>Alternate Phone</label>
                <input
                  type='number'
                  className='form-control'
                  value={person.phoneNumberOptional}
                  onChange={e =>
                    handleInputChange(
                      e,
                      index,
                      'phoneNumberOptional',
                      'contactPersons'
                    )
                  }
                />
              </div>
              <div className='col-md-4 mt-3'>
                <label className='form-label'>
                  Designation <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={person.designation}
                  onChange={e =>
                    handleInputChange(e, index, 'designation', 'contactPersons')
                  }
                />
              </div>
            </div>
          ))}
          <div className='row mt-3'>
            <div className='col-md-4'>
              {countContactPerson < 5 ? (
                <button
                  type='button'
                  onClick={handleAddContactPerson}
                  className='btn add-btn text-white'
                >
                  Add New Contact
                </button>
              ) : (
                ' '
              )}
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-12'>
              <button className='btn add-btn text-white' type='submit'>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      <Modal show={showSuccessModal} centered>
        <Modal.Body>
          <div class='my-8 text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              class='w-14 shrink-0 fill-green-500 inline'
              viewBox='0 0 512 512'
            >
              <path
                d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                data-original='#000000'
              />
              <path
                d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                data-original='#000000'
              />
            </svg>
            <h4 class='text-xl text-gray-800 font-semibold mt-4'>Good Job</h4>
            <p class='text-sm text-gray-500 leading-relaxed mt-4'>
              Client Added Succesfully!
            </p>
          </div>

          <button
            type='button'
            onClick={handleCloseModal}
            class='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
          >
            Okay
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={showDeclineModal} centered>
        <Modal.Body>
          <div class='my-8 text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              class='w-14 fill-red-500 inline'
              viewBox='0 0 286.054 286.054'
            >
              <path
                fill='#e2574c'
                d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                data-original='#e2574c'
              ></path>
            </svg>
            <h4 class='text-xl text-gray-800 font-semibold mt-4'>
              Error Occured
            </h4>
            <p class='text-sm text-gray-500 leading-relaxed mt-4'>
              Something went wrong!
            </p>
          </div>

          <button
            type='button'
            onClick={handleCloseModal}
            class='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
          >
            Okay
          </button>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default AddClient
