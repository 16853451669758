import React, { Fragment, useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Select from 'react-select'
import { API_URL } from '../utils/Constants/URLS'
import { useSelector } from 'react-redux'
import '../../css/style.scss'

export const AddEmpModal = () => {
  const userDetails = useSelector(store => store.user)
  const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  let [email, setEmail] = useState('')
  const [phoneNumberOptional, setPhoneNumberOptional] = useState('')
  const [businessArea, setBusinessArea] = useState(null)
  const [businessLine, setBusinessLine] = useState('')
  const [businessLineList, setBusinessLineList] = useState([{}])
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessage1, setErrorMessage1] = useState('')
  const [role, setRole] = useState(
    userDetails.role === 'super_admin'
      ? 'admin'
      : userDetails.role === 'admin'
      ? 'manager'
      : 'executive'
  )

  const token = localStorage.getItem('token')

  const businessAreaData = [
    { value: 'NB', label: 'NB' },
    { value: 'SB', label: 'SB' },
    { value: 'NE', label: 'NE' }
  ]

  const [validated, setValidated] = useState(false)

  const handleAddAdmin = async e => {
    e.preventDefault()
    const token = localStorage.getItem('token')
    email = email.concat('@greenergy.net.in')
    const password = 'password'
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)

    try {
      const url = `${API_URL}users/admin`
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          name,
          email,
          password,
          phoneNumber
        })
      })
      const result = await res.json()
      if (result.error === false) {
        setShowSuccessModal(true)
        setShowModal(false)
      } else {
        setShowDeclineModal(true)
        setShowModal(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleAddResource = async e => {
    e.preventDefault()
    console.log(businessLine)
    const password = 'password'
    email = email.concat('@greenergy.net.in')
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)
    try {
      const url = `${API_URL}users/${
        role === 'manager' ? 'manager' : 'executive'
      }`
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          name,
          email,
          phoneNumber,
          password,
          businessLine,
          businessArea
        })
      })
      const result = await res.json()
      if (result.error === false) {
        setShowSuccessModal(true)
        setShowModal(false)
      } else {
        setShowModal(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const fetchBusinessLines = async () => {
      const url = `${API_URL}business-lines?pagination=false`
      const data = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      const result = await data.json()
      setBusinessLineList(
        result.data.docs.map(item => ({
          value: item.id,
          label: item.name
        }))
      )
    }

    fetchBusinessLines()
  }, [token])
  const handleCloseModal = () => {
    setShowModal(false)
    setShowSuccessModal(false)
    setShowDeclineModal(false)
  }
  const handleShowModal = () => {
    setShowModal(true)
  }

  const validatePhoneNumber = () => {
    const regex = /^(?:[6-9]\d{9})$/
    console.log(regex.test(phoneNumber))

    if (regex.test(phoneNumber) === false) {
      setErrorMessage('Please Enter a valid phone number.')
    } else {
      setErrorMessage('')
    }
  }
  const validatePhoneNumberOptional = () => {
    const regex = /^(?:[6-9]\d{9})$/
    console.log(regex.test(phoneNumber))

    if (regex.test(phoneNumber) === false) {
      setErrorMessage1('Please Enter a valid phone number.')
    } else {
      setErrorMessage1('')
    }
  }
  return (
    <Fragment>
      <Button
        type='button'
        color='success'
        className='btn add-btn'
        onClick={handleShowModal}
      >
        + Add Employee
      </Button>
      <div
        className='modal show'
        style={{ display: 'block', position: 'initial' }}
      >
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header className='bg-indigo-100' closeButton>
            <Modal.Title>Add Employee</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <Form noValidate validated={validated}>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12'>
                    <Form.Select
                      value={role}
                      onChange={e => setRole(e.target.value)}
                    >
                      <option value='admin'>Admin</option>
                      <option value='manager'>Manager</option>
                      <option value='executive'>Executive</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md='12' controlId='validationCustom01'>
                    <Form.Label>
                      Name <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='text'
                      defaultValue={name}
                      onChange={e => setName(e.target.value)}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please enter the name
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='12' controlId='validationCustom02'>
                    <Form.Label>
                      Phone Number <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='number'
                      defaultValue={phoneNumber}
                      onChange={e => setPhoneNumber(e.target.value)}
                      onBlur={validatePhoneNumber}
                    />
                    <Form.Control.Feedback type='is-invalid'>
                      <span className='text-danger'>{errorMessage}</span>
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='12' controlId='validationCustom02'>
                    <Form.Label>Alternate Phone Number</Form.Label>
                    <Form.Control
                      type='number'
                      defaultValue={phoneNumberOptional}
                      onChange={e => setPhoneNumberOptional(e.target.value)}
                      onBlur={validatePhoneNumberOptional}
                    />
                    <Form.Control.Feedback type='is-invalid'>
                      <span className='text-danger'>{errorMessage1}</span>
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md='12'
                    controlId='validationCustomUsername'
                  >
                    <Form.Label>
                      Email <span className='text-danger'>*</span>
                    </Form.Label>
                    <div className='input-group has-validation'>
                      <Form.Control
                        required
                        type='text'
                        defaultValue={email}
                        aria-describedby='inputGroupPostpend'
                        onChange={e => setEmail(e.target.value)}
                      />
                      <InputGroup.Text id='inputGroupPostpend'>
                        @greenergy.net.in
                      </InputGroup.Text>
                      <Form.Control.Feedback type='invalid'>
                        Please enter the email.
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  {role === 'manager' || role === 'executive' ? (
                    <Form.Group
                      as={Col}
                      md='12'
                      controlId='validationCustomUsername'
                    >
                      <Form.Label>
                        Business Area <span className='text-danger'>*</span>
                      </Form.Label>
                      <Select
                        onChange={selectedOptions =>
                          setBusinessArea(
                            selectedOptions
                              ? selectedOptions.map(option => option.label)
                              : []
                          )
                        }
                        options={businessAreaData}
                        isMulti={true}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Please select business area
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    ''
                  )}

                  {role === 'manager' || role === 'executive' ? (
                    <Form.Group
                      as={Col}
                      md='12'
                      controlId='validationCustomUsername'
                    >
                      <Form.Label>
                        Business Line <span className='text-danger'>*</span>
                      </Form.Label>
                      <Select
                        options={businessLineList}
                        onChange={e => setBusinessLine(e.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Please select business line
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    ''
                  )}

                  <Form.Group
                    className='mt-2'
                    as={Col}
                    md='12'
                    controlId='validationCustom02'
                  ></Form.Group>
                </Row>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              onClick={role === 'admin' ? handleAddAdmin : handleAddResource}
              className='btn add-btn text-white'
            >
              Add Employee
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>Good Job</h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                Employee Added Succesfully!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={showDeclineModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 fill-red-500 inline'
                viewBox='0 0 286.054 286.054'
              >
                <path
                  fill='#e2574c'
                  d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                  data-original='#e2574c'
                ></path>
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Error Occured
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                Something went wrong!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  )
}
