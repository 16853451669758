import { SidebarItems } from './SidebarContainer'
import React from 'react'
import SidebarContainer from './SidebarContainer'
import '../../css/Sidebar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
  faChartPie,
  faEye,
  faPowerOff,
  faSitemap,
  faUserGear,
  faUsers,
  faUsersViewfinder
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

const Sidebar = () => {
  const userDetails = useSelector(store => store.user)
  return (
    <SidebarContainer>
      <SidebarItems
        icon={<FontAwesomeIcon icon={faChartPie} />}
        text='Dashboard'
        link='/dashboard'
      />
      <SidebarItems
        icon={<FontAwesomeIcon icon={faUserGear} />}
        text='CRM Resources'
        link='org_contact'
      />
      {userDetails.role === 'super_admin' || userDetails.role === 'admin' ? (
        <SidebarItems
          icon={<FontAwesomeIcon icon={faSitemap} />}
          text='Business Line'
          link='business_line'
        />
      ) : (
        ''
      )}

      <SidebarItems
        icon={<FontAwesomeIcon icon={faUsers} />}
        text='Add Client'
        link='/add_client_contact'
      />
      <SidebarItems
        icon={<FontAwesomeIcon icon={faUsersViewfinder} />}
        text='View Client'
        link='/client_contact'
      />
      {userDetails.role === 'manager' || userDetails.role === 'executive' ? (
        <SidebarItems
          icon={<FontAwesomeIcon icon={faAddressCard} />}
          text='New Lead'
          link='/lead'
        />
      ) : (
        ''
      )}

      <SidebarItems
        icon={<FontAwesomeIcon icon={faEye} />}
        text='View Lead'
        link='/view_leads'
      />
      <SidebarItems
        icon={<FontAwesomeIcon icon={faPowerOff} />}
        text='Sign Out'
      />
    </SidebarContainer>
  )
}

export default Sidebar
