import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
const Navbar = () => {
    const userDtails=useSelector(store=>store.user);
    return (
        <Fragment>
            <nav className="fixed top-0 z-30 w-full max-[680px]:py-3 bg-[#38454a] border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="items-center justify-start rtl:justify-end hidden md:block sm:block">
                            <h5><span className='text-white'>Welcome, </span> <span className='text-danger'>{userDtails.name}</span></h5>
                        </div>
                    </div>
                </div>
            </nav>
        </Fragment>
    )
}

export default Navbar