import React, { Fragment, useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faEnvelope,
  faHourglassHalf
} from '@fortawesome/free-solid-svg-icons'
import '../css/common.css'
import '../css/ClientProfile.css'
import { useParams } from 'react-router-dom'
import { API_URL } from './utils/Constants/URLS'
import PageHeader from './utils/PageHeader'

const LeadProfile = () => {
  const [leadDetails, setLeadDetails] = useState('')
  const [clientDetails, setClientDetails] = useState()
  const [crossSellData, setCrossSellData] = useState([])
  const [activeBtn, setActiveBtn] = useState('Capture Details')
  const token = localStorage.getItem('token')
  const { leadId } = useParams()

  useEffect(() => {
    const getLeadDetails = async () => {
      const url = `${API_URL}leads/${leadId}`

      const data = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      const result = await data.json()
      setLeadDetails(result.data)
      console.log(result.data);
      
    }
    getLeadDetails()
    getCrossSellData()
  }, [leadId, token])

  const getCrossSellData = async () => {
    const res = await fetch(`${API_URL}cross-sells?pagination=false&leadId=${leadId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/Json',
        Authorization: `Bearer ${token}`
      }
    })
    const result = await res.json()
    setCrossSellData(result?.data?.docs)
  }

  const handleBtnClick = btn => {
    setActiveBtn(btn)
  }

  return (
    <Fragment>
      <PageHeader
        pageName='LEAD DETAILS'
        breadcrump1='Home'
        breadcrump2='Lead Details'
        link='/dashboard'
      />
      <div className='pl-5 pr-3'>
        <Container fluid>
          <Row className='mt-4'>
            <Col md={3}>
              <Card className='p-2'>
                <CardBody className='border-bottom'>
                  <h4 className='text-slate-600'>
                    {leadDetails?.leadJourneyName}
                  </h4>
                  <p className='mt-3 '>
                    Amount: Rs{' '}
                    {leadDetails?.productRequirements?.proposedDealAmount}
                  </p>
                  <p className='mt-3'>
                    Close Date:{' '}
                    {leadDetails?.enquiryDetails?.tentativeClosureDate?.substring(0, 10)}
                  </p>
                  <span className='d-flex justify-content-between'>
                    <FontAwesomeIcon
                      className='bg-slate-200 p-2 rounded-full text-slate-700'
                      icon={faEnvelope}
                    />
                    <FontAwesomeIcon
                      className='bg-slate-200 p-2 rounded-full text-slate-700'
                      icon={faHourglassHalf}
                    />
                    <FontAwesomeIcon
                      className='bg-slate-200 p-2 rounded-full text-slate-700'
                      icon={faCalendar}
                    />
                  </span>
                </CardBody>
                <CardBody className='border-bottom'>
                  <h6 className='text-slate-600'>Lead Summary</h6>
                  <span>
                    <label className='form-label'>Owner</label>
                    <p>{leadDetails?.leadOwner?.name}</p>
                  </span>
                  <span>
                    <label className='form-label'>Company</label>
                    <p>{leadDetails?.client?.companyName}</p>
                  </span>
                  <span>
                    <label className='form-label'>Type of Sale</label>
                    <p>{leadDetails?.productRequirements?.typeOfSale}</p>
                  </span>
                  <span>
                    <label className='form-label'>Nature of Sale</label>
                    <p>{leadDetails?.productRequirements?.natureOfSale}</p>
                  </span>
                  <span>
                    <label className='form-label'>Business Line</label>
                    <p>{leadDetails?.businessLine?.name}</p>
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col md={9}>
              <Card>
                <CardBody>
                  <div className='d-flex'>
                    <div
                      className={`client-activities ${
                        activeBtn === 'Capture Details' ? 'active' : ''
                      } p-1 col-md-3 text-center`}
                    >
                      <span onClick={() => handleBtnClick('Capture Details')}>
                        Known Universe
                      </span>
                    </div>
                    <div
                      className={`client-activities ${
                        activeBtn === 'Update Platform' ? 'active' : ''
                      } p-1 col-md-3 text-center`}
                    >
                      <span onClick={() => handleBtnClick('Update Platform')}>
                        Update Platform
                      </span>
                    </div>
                    <div
                      className={`client-activities ${
                        activeBtn === 'Agreement' ? 'active' : ''
                      } p-1 col-md-3 text-center`}
                    >
                      <span onClick={() => handleBtnClick('Agreement')}>
                        Agreement
                      </span>
                    </div>

                    <div
                      className={`client-activities ${
                        activeBtn === 'cross-sell' ? 'active' : ''
                      } p-1 col-md-3 text-center`}
                    >
                      <span onClick={() => handleBtnClick('cross-sell')}>
                        Cross Sell
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <TabContent activeTab={activeBtn}>
                <TabPane tabId='Capture Details'>
                  <Card className='mt-3'>
                    <CardBody>
                      <Row>
                        <h4>Client Details</h4>
                        <hr></hr>
                        <Col md={4}>
                          <label className='form-label'>
                            <strong>Company Name</strong>
                          </label>
                          <p>{leadDetails?.client?.companyName}</p>
                        </Col>
                        <Col md={4}>
                          <label className='form-label'>
                            <strong>Type of Organisation</strong>
                          </label>
                          <p>{leadDetails?.client?.organizationType}</p>
                        </Col>
                        <Col md={4}>
                          <label className='form-label'>
                            <strong>GSTIN</strong>
                          </label>
                          <p>{leadDetails?.client?.gstNumber}</p>
                        </Col>
                      </Row>
                      <Row>
                        <h4>Product Requirements</h4>
                        <hr></hr>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Type of Sale</strong>
                          </label>
                          <p>{leadDetails?.productRequirements?.typeOfSale}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Nature of Sale</strong>
                          </label>
                          <p>
                            {leadDetails?.productRequirements?.natureOfSale}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Industry</strong>
                          </label>
                          <p>{leadDetails?.productRequirements?.industry}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Proposed Deal Amount</strong>
                          </label>
                          <p>
                            Rs{' '}
                            {
                              leadDetails?.productRequirements
                                ?.proposedDealAmount
                            }
                          </p>
                        </Col>

                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Enquiry Details</strong>
                          </label>
                          <p>
                            {leadDetails?.productRequirements?.enquiryDetails}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Lead Source</strong>
                          </label>
                          <p>{leadDetails?.productRequirements?.leadSource}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Description</strong>
                          </label>
                          <p>{leadDetails?.productRequirements?.description}</p>
                        </Col>
                      </Row>
                      <Row>
                        <h4>Enquiry Details</h4>
                        <hr></hr>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Project Type</strong>
                          </label>
                          <p>{leadDetails?.enquiryDetails?.projectType||'-'}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Established Contact </strong>
                          </label>
                          <p>
                            {leadDetails?.enquiryDetails?.contactEstablished ===
                            true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Information Received</strong>
                          </label>
                          <p>
                            {leadDetails?.enquiryDetails
                              ?.informationReceived === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Identified User</strong>
                          </label>
                          <p>
                            {leadDetails?.enquiryDetails?.userIdentified ===
                            true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>

                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Any Past Meeting</strong>
                          </label>
                          <p>
                            {leadDetails?.enquiryDetails?.anyPastMeeting ===
                            true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Meeting Date</strong>
                          </label>
                          <p>{leadDetails?.enquiryDetails?.meetingDate?.substring(0, 10)}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Meeting Notes</strong>
                          </label>
                          <p>{leadDetails?.enquiryDetails?.meetingNotes}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Tentative Closure Date</strong>
                          </label>
                          <p>
                            {leadDetails?.enquiryDetails?.tentativeClosureDate?.substring(0, 10)}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Need Identified</strong>
                          </label>
                          <p>
                            {leadDetails?.enquiryDetails?.needIdentified ===
                            true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId='Update Platform'>
                  <Card className='mt-3'>
                    <CardBody>
                      <Row>
                        <h4>Marketing Platform</h4>
                        <hr></hr>
                        <Col md={12}>
                          <label className='form-label'>
                            <strong>Identified Influencer</strong>
                          </label>
                          <p>
                            {leadDetails?.marketingPlatform
                              ?.influencerIdentified === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Influencer Name</strong>
                          </label>
                          <p>{leadDetails?.marketingPlatform?.contactPerson?.name||'-'}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Influencer Phone</strong>
                          </label>
                          <p>{leadDetails?.marketingPlatform?.contactPerson?.phoneNumber||'-'}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Influencer Email</strong>
                          </label>
                          <p>{leadDetails?.marketingPlatform?.contactPerson?.email||'-'}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Influencer Designation</strong>
                          </label>
                          <p>{leadDetails?.marketingPlatform?.contactPerson?.designation||'-'}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Equipment Confirmed</strong>
                          </label>
                          <p>
                            {leadDetails?.marketingPlatform
                              ?.equipmentConfirmed === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Submitted 1st Offer</strong>
                          </label>
                          <p>
                            {leadDetails?.marketingPlatform
                              ?.submittedFirstOffer === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Compititors</strong>
                          </label>
                          <p>{leadDetails?.marketingPlatform?.competitors}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Statutory Requirements Identified</strong>
                          </label>
                          <p>
                            {leadDetails?.marketingPlatform
                              ?.statutoryRequirementsIdentified === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Any Past Meetings</strong>
                          </label>
                          <p>
                            {leadDetails?.marketingPlatform?.anyPastMeeting ===
                            true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Meeting Date</strong>
                          </label>
                          <p>
                            {leadDetails?.marketingPlatform?.meetingDate?.substring(0, 10)||'-'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Meeting Notes</strong>
                          </label>
                          <p>
                            {leadDetails?.marketingPlatform?.meetingNotes||'-'}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <h4>Working Platform</h4>
                        <hr></hr>
                        <Col md={12}>
                          <label className='form-label'>
                            <strong>Is End User Different For Customer</strong>
                          </label>
                          <p>
                            {leadDetails?.workingPlatform?.differentEndUser ===
                            true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>User Name</strong>
                          </label>
                          <p>
                            {leadDetails?.workingPlatform?.contactPerson?.name}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>User Email</strong>
                          </label>
                          <p>
                            {leadDetails?.workingPlatform?.contactPerson?.email}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>User Phone</strong>
                          </label>
                          <p>
                            {
                              leadDetails?.workingPlatform?.contactPerson
                                ?.phoneNumber
                            }
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>User Designation</strong>
                          </label>
                          <p>
                            {
                              leadDetails?.workingPlatform?.contactPerson
                                ?.designation
                            }
                          </p>
                        </Col>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>
                              Technical and Commercial Discussion Completed?
                            </strong>
                          </label>
                          <p>
                            {leadDetails?.workingPlatform
                              ?.technicalAndCommercialDiscussionCompleted ===
                            true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>Commercial Proposal Submitted?</strong>
                          </label>
                          <p>
                            {leadDetails?.workingPlatform
                              ?.commercialProposalSubmitted === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>
                              First Round of Commercial negotiation started?
                            </strong>
                          </label>
                          <p>
                            {leadDetails?.workingPlatform
                              ?.firstRoundCommercialNegotiationStarted === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </Col>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>Approved Finance Description</strong>
                          </label>
                          <p>
                            {
                              leadDetails?.workingPlatform
                                ?.approvedFinanceDescription
                            }
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Installation Visit Done?</strong>
                          </label>
                          <p></p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Date and Time of Visit?</strong>
                          </label>
                          <p>{leadDetails?.workingPlatform?.dateOfVisit?.substring(0, 10)||''}</p>
                        </Col>
                        <Col md={3}>
                          <label className='form-label'>
                            <strong>Visit Note</strong>
                          </label>
                          <p>{leadDetails?.workingPlatform?.visitNotes||''}</p>
                        </Col>
                      </Row>
                      <Row>
                        <h4>Buying Platform</h4>
                        <hr></hr>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>Stage Update</strong>
                          </label>
                          <p>{leadDetails?.buyingPlatform?.stage}</p>
                        </Col>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>Lost or Won against whom? </strong>
                          </label>
                          <p>{leadDetails?.buyingPlatform?.lostOrWonAgainst}</p>
                        </Col>
                        <Col md={12}>
                          <label className='form-label'>
                            <strong>Reason for Lost/Drop</strong>
                          </label>
                          <p>{leadDetails?.buyingPlatform?.closingReason}</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId='Agreement'>
                  <Card className='mt-3'>
                    <CardBody>
                      <Row>
                        <h4>Agreement</h4>
                        <hr></hr>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>Stage Update</strong>
                          </label>
                          <p>{leadDetails?.agreement?.stage}</p>
                        </Col>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>Lost or Won against whom? </strong>
                          </label>
                          <p>{leadDetails?.agreement?.lostOrWonAgainst}</p>
                        </Col>
                        <Col md={12}>
                          <label className='form-label'>
                            <strong>Reason for Lost/Drop</strong>
                          </label>
                          <p>{leadDetails?.agreement?.closingReason}</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId='cross-sell'>
                  <Card className='mt-3'>
                    <CardBody>
                      <Table>
                        <thead>
                          <tr>
                            <th>Product Line</th>
                            <th>Model</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {crossSellData.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.productLine.productLineName}</td>
                                <td>{data.model.modelName}</td>
                                <td>{data.value}</td>
                                <td>
                                  {data.status === 'added' ? <span className='text-green-500'>Won</span> : <span className='text-red-500'>Lost</span>}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default LeadProfile
