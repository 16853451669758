import React, { Fragment } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';

const MonthlyRevenueGrowth = () => {
    const series = [{
        name: 'XYZ MOTORS',
        data: [{
            x: new Date('2018-01-12').getTime(),
            y: 140
        }, {
            x: new Date('2018-04-11').getTime(),
            y: 162
        }, {
            x: new Date('2018-04-12').getTime(),
            y: 157
        }, {
            x: new Date('2018-04-13').getTime(),
            y: 161
        }, {
            x: new Date('2018-04-14').getTime(),
            y: 166
        }, {
            x: new Date('2018-04-15').getTime(),
            y: 169
        }, {
            x: new Date('2018-04-16').getTime(),
            y: 172
        }, {
            x: new Date('2018-04-17').getTime(),
            y: 177
        }, {
            x: new Date('2018-04-18').getTime(),
            y: 181
        }, {
            x: new Date('2018-04-20').getTime(),
            y: 178
        }, {
            x: new Date('2018-04-21').getTime(),
            y: 173
        }, {
            x: new Date('2018-04-22').getTime(),
            y: 169
        }, {
            x: new Date('2018-05-03').getTime(),
            y: 147
        }, {
            x: new Date('2018-05-04').getTime(),
            y: 142
        }, {
            x: new Date('2018-05-05').getTime(),
            y: 147
        }, {
            x: new Date('2018-05-07').getTime(),
            y: 151
        }, {
            x: new Date('2018-05-08').getTime(),
            y: 155
        }, {
            x: new Date('2018-05-21').getTime(),
            y: 173
        }, {
            x: new Date('2018-05-22').getTime(),
            y: 169
        }, {
            x: new Date('2018-05-23').getTime(),
            y: 163
        }, {
            x: new Date('2018-05-24').getTime(),
            y: 159
        }, {
            x: new Date('2018-05-25').getTime(),
            y: 164
        }, {
            x: new Date('2018-05-26').getTime(),
            y: 168
        }, {
            x: new Date('2018-05-27').getTime(),
            y: 172
        }, {
            x: new Date('2018-05-28').getTime(),
            y: 169
        }, {
            x: new Date('2018-05-29').getTime(),
            y: 163
        }, {
            x: new Date('2018-05-30').getTime(),
            y: 162
        },]
    }];

    var options = {
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        colors: ["Green"],
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        yaxis: {
            showAlways: true,
            labels: {
                show: true,
                formatter: function (val) {
                    return (val / 1000000).toFixed(0);
                },
            },
            title: {
                text: 'Price',
                style: {
                    fontWeight: 500,
                },
            },
        },
        xaxis: {
            type: 'datetime',

        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return (val / 1000000).toFixed(0);
                }
            }
        }
    };
    return (
        <Fragment>
            <Card>
                <CardHeader>Monthly Revenue Growth</CardHeader>
                <CardBody>
                    <ReactApexChart
                        dir="ltr"
                        options={options}
                        series={series}
                        type="area"
                        height="350"
                        className="apex-charts"
                    />
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default MonthlyRevenueGrowth