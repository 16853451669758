import React, { Fragment, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  NavItem,
  Nav,
  TabContent,
  TabPane
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import SubProgessBar from './utils/SubProgessBar'
import '../css/lead.css'
import ReachedAgreement from './Forms/FormComponents/ReachedAgreement'
import { useSelector } from 'react-redux'
import LeadJourneyName from './Modals/LeadJourneyName'
import PageHeader from './utils/PageHeader'
import CrossSellModal from './Modals/CrossSellModal'
const Lead = props => {
  const { condition, leadType } = props
  const [activeVerticalTab, setactiveVerticalTab] = useState('capture_details')
  const [activeSubProgressBarTab, setActiveSubProgressBarTab] =
    useState('Client')
  const [tabBgColorCaptureDetails, setTabBgColorCaptureDetails] = useState('')
  const [tabBgColorUpdatePlatform, setTabBgColorUpdatePlatform] = useState('')
  const useDetails = useSelector(store => store.user)
  const leadDetails = useSelector(store => store.deal)
  const { id } = useParams()
  const { resid } = useParams()

  useEffect(() => {
    if (leadType === 'new_lead') {
      setactiveVerticalTab('capture_details')
    } else if (leadType === 'existing_lead' && leadDetails.client === null) {
      setActiveSubProgressBarTab('Client')
      setactiveVerticalTab('capture_details')
    } else if (
      leadType === 'existing_lead' &&
      leadDetails.productRequirements.status === 'pending'
    ) {
      setActiveSubProgressBarTab('Product')
      setactiveVerticalTab('capture_details')
    } else if (
      leadType === 'existing_lead' &&
      leadDetails.enquiryDetails.status === 'pending'
    ) {
      setActiveSubProgressBarTab('Enquiry')
      setactiveVerticalTab('capture_details')
    } else if (
      leadType === 'existing_lead' &&
      leadDetails.marketingPlatform.status === 'pending'
    ) {
      setActiveSubProgressBarTab('Marketing')
      setactiveVerticalTab('update_platform')
      setTabBgColorCaptureDetails('bg-gray-300')
    } else if (
      leadType === 'existing_lead' &&
      leadDetails.workingPlatform.status === 'pending'
    ) {
      setActiveSubProgressBarTab('Working')
      setactiveVerticalTab('update_platform')
      setTabBgColorCaptureDetails('bg-gray-300')
    } else if (
      leadType === 'existing_lead' &&
      leadDetails.buyingPlatform.status === 'pending'
    ) {
      setActiveSubProgressBarTab('Buying')
      setactiveVerticalTab('update_platform')
      setTabBgColorCaptureDetails('bg-gray-300')
    } else {
      setactiveVerticalTab('agreement')
      setTabBgColorUpdatePlatform('bg-gray-300')
      setTabBgColorCaptureDetails('bg-gray-300')
    }
  }, [leadType, leadDetails])

  return (
    <Fragment>
      <PageHeader
        pageName='LEAD JOURNEY'
        breadcrump1='Home'
        breadcrump2='Lead Journey'
        link='/dashboard'
      />
      <Row className='ms-4 p-4'>
        <Col xl={12}>
          <Card>
            <CardHeader className='d-flex justify-content-between'>
              <div className='col-md-6'>
                <h5 className='card-title mb-0'>
                  {leadDetails?.leadJourneyName}
                </h5>
              </div>
              <div className='col-md-6'>
                <h5 className='text-right text-md'>{useDetails?.name}</h5>
              </div>
            </CardHeader>
            <CardBody>
              <form className='vertical-navs-step form-steps'>
                <Row className='gy-5'>
                  <Col lg={4}>
                    <Nav className='flex-column custom-nav nav-pills'>
                      <NavItem
                        className={`border p-2 mt-4 ${tabBgColorCaptureDetails}`}
                      >
                        <Link
                          to='#'
                          className='link-dark link-underline-opacity-0'
                        >
                          <strong>
                            <span className='step-title me-2'>
                              {tabBgColorCaptureDetails === 'bg-gray-300' ? (
                                <FontAwesomeIcon
                                  className='text-green-400 me-2'
                                  icon={faCircleCheck}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className='text-red-500 me-2'
                                  icon={faCircleXmark}
                                />
                              )}
                              Step 1
                            </span>
                          </strong>
                          Capture Details
                        </Link>
                      </NavItem>

                      <>
                        <NavItem
                          className={`border p-2 mt-4 ${tabBgColorUpdatePlatform}`}
                        >
                          <Link
                            href='#'
                            className='link-dark link-underline-opacity-0'
                          >
                            <span className='step-title me-2'>
                              {tabBgColorUpdatePlatform === 'bg-gray-300' ? (
                                <FontAwesomeIcon
                                  className='text-green-400 me-2'
                                  icon={faCircleCheck}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className='text-red-500 me-2'
                                  icon={faCircleXmark}
                                />
                              )}
                              Step 2
                            </span>
                            Update Platform
                          </Link>
                        </NavItem>
                        <NavItem className={`p-2 border mt-4`}>
                          <Link
                            href='#'
                            className='link-dark link-underline-opacity-0'
                          >
                            <span className='step-title me-2'>
                              <FontAwesomeIcon
                                className='text-red-500 me-2'
                                icon={faCircleXmark}
                              />
                              Step 3
                            </span>
                            Agreement
                          </Link>
                        </NavItem>
                      </>
                    </Nav>
                  </Col>
                  <Col lg={8}>
                    <div className='px-lg-4'>
                      <TabContent
                        className='mt-3'
                        activeTab={activeVerticalTab}
                      >
                        <TabPane tabId='capture_details'>
                          <div className='d-flex justify-content-between'>
                            <span>
                              <h4 className='card-title text-left mb-3'>
                                Capture Details
                              </h4>
                            </span>
                            <span>
                              {leadDetails?.client !== null &&
                              leadDetails?.productRequirements?.status !==
                                'pending' ? (
                                <CrossSellModal />
                              ) : (
                                ''
                              )}
                            </span>
                          </div>

                          <SubProgessBar
                            itemOne={
                              leadDetails?.client === null
                                ? 'Client'
                                : 'Client (Done)'
                            }
                            itemTwo={
                              leadDetails?.productRequirements?.status ===
                              'pending'
                                ? 'Product'
                                : 'Product (Done)'
                            }
                            itemThree={
                              leadDetails?.enquiryDetails?.status === 'pending'
                                ? 'Enquiry'
                                : 'Enquiry (Done)'
                            }
                            initialActiveTab={activeSubProgressBarTab}
                            id={id}
                          />
                        </TabPane>
                        <TabPane tabId='update_platform'>
                          <div className='d-flex justify-content-between'>
                            <span>
                              <h4 className='card-title text-left mb-3'>
                                Update Platform
                              </h4>
                            </span>
                            <span>
                              <CrossSellModal />
                            </span>
                          </div>
                          <SubProgessBar
                            itemOne={
                              leadDetails?.marketingPlatform?.status ===
                              'pending'
                                ? 'Marketing'
                                : 'Marketing (Done)'
                            }
                            itemTwo={
                              leadDetails?.workingPlatform?.status === 'pending'
                                ? 'Working'
                                : 'Working (Done)'
                            }
                            itemThree={
                              leadDetails?.buyingPlatform?.status === 'pending'
                                ? 'Buying'
                                : 'Buying (Done)'
                            }
                            initialActiveTab={activeSubProgressBarTab}
                          />
                        </TabPane>
                        <TabPane tabId='agreement'>
                          <div className='d-flex justify-content-between'>
                            <span>
                              <h4 className='card-title text-left mb-3'>
                                Reached Agreement
                              </h4>
                            </span>
                            <span>
                              <CrossSellModal />
                            </span>
                          </div>
                          <ReachedAgreement />
                        </TabPane>
                      </TabContent>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <LeadJourneyName isOpen={condition} clientId={resid} />
      </Row>
    </Fragment>
  )
}

export default Lead
