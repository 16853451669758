import React, { useEffect, useState } from 'react'
import {
  Button,
  Input,
  Container,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import '../css/BusinessLine.css'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API_URL } from './utils/Constants/URLS'
import PageHeader from './utils/PageHeader'

const BusinessLine = () => {
  const [name, setName] = useState('') // State to keep track of the current task input
  const [businessLineList, setBusinessLineList] = useState([]) // State to keep track of the list of tasks
  const [productLineList, setProductLineList] = useState([])
  const [businessLineName, setBusinessLineName] = useState('')
  const [productLineName, setProductLineName] = useState('')
  const [editedBlName, setEditedBlName] = useState('')
  const [editedPlName, setEditedPlName] = useState('')
  const [showProductLine, setShowProductLine] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [blForPl, setBlForPl] = useState('')
  const [isEdit, setIsEdit] = useState(null)
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  const handleAddBusinessLine = async e => {
    e.preventDefault()
    try {
      const url = `${API_URL}business-lines`

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          name
        })
      })
      const result = await res.json()
      if (result.error === false) {
        setModalMessage('Business Line')
        setShowSuccessModal(true)
      }
      console.log(result)

      // Append the new business line instead of overwriting the list
      setBusinessLineList([...businessLineList, result.data])
    } catch (e) {
      console.log(e)
    }
  }

  const handleEditBusinessLine = async (e, id) => {
    e.preventDefault()
    try {
      const res = await fetch(`${API_URL}/business-lines/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          name: editedBlName
        })
      })
      const result = await res.json()
      if (result.error === false) {
        navigate('/business_line')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleEditProductLine = async (e, id) => {
    e.preventDefault()
    try {
      const res = await fetch(`${API_URL}/product-lines/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          productLineName: editedPlName
        })
      })
      const result = await res.json()
      if (result.error === false) {
        navigate('/business_line')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleCloseModal = () => {
    setShowSuccessModal(false)
  }

  const showProductLineSection = (bl_id, bl_name) => {
    setShowProductLine(true)
    setBlForPl(bl_id)
    setBusinessLineName(bl_name)
  }

  const handleAddProductLine = async e => {
    e.preventDefault()
    try {
      const url = `${API_URL}product-lines`
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          productLineName,
          businessLine: blForPl
        })
      })
      const result = await res.json()
      if (result.error === false) {
        setModalMessage('Product Line')
        setShowSuccessModal(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const fetchBusinessLines = async () => {
      try {
        const url = `${API_URL}business-lines?pagination=false`
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        const result = await res.json()
        setBusinessLineList(result.data.docs)
      } catch (error) {
        console.error(error)
      }
    }

    fetchBusinessLines()
  }, [token])

  useEffect(() => {
    const fetchProductLines = async () => {
      try {
        const url = `${API_URL}product-lines?pagination=false&businessLine=${blForPl}`
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        const result = await res.json()

        setProductLineList(result.data.docs)
      } catch (error) {
        console.error(error)
      }
    }
    if (blForPl !== '') {
      fetchProductLines()
    }
  }, [token, blForPl])

  const redirectToModel = (productLineId, businessLineId) => {
    navigate(`/model/${productLineId}/${businessLineId}`)
  }

  return (
    <>
      <PageHeader
        pageName='BUSINESS LINE'
        breadcrump1='Home'
        breadcrump2='Business Line'
        link='/dashboard'
      />
      <div className='lg:ml-2 xl:ml-2 2xl:ml-2 md:ml-2'>
        <Container fluid>
          <Row>
            <Col className='d-flex border-end mt-3' md={4} sm={12}>
              <Card className='w-100'>
                <CardHeader>Add Business Line</CardHeader>
                <CardBody>
                  <form onSubmit={handleAddBusinessLine}>
                    <Row>
                      <Col md={8} sm={12} className='mt-1'>
                        <Input
                          className='form-control'
                          type='text'
                          name='name'
                          value={name}
                          onChange={e => setName(e.target.value)}
                          placeholder='Enter business line'
                          autoComplete='off'
                          required
                        />
                      </Col>
                      <Col md={4} sm={12} className='mt-1'>
                        <Button
                          className='lg:ml-2 xl:ml-2 2xl:ml-2 btn add-btn'
                          type='submit'
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
            <Col md={8} sm={12} className='mt-3'>
              <Card>
                <CardHeader>Add Product Line</CardHeader>
                <CardBody>
                  <form onSubmit={handleAddProductLine}>
                    <Row>
                      <Col md={5} sm={12} className='mt-1'>
                        <Input
                          required
                          className='form-control'
                          type='text'
                          name='name'
                          value={businessLineName}
                          placeholder='Business Line'
                          readOnly
                        />
                      </Col>
                      <Col md={5} sm={12} className='mt-1'>
                        <Input
                          className='form-control'
                          type='text'
                          name='name'
                          value={productLineName}
                          onChange={e => setProductLineName(e.target.value)}
                          placeholder='Enter Product Line'
                          autoComplete='off'
                          required
                        />
                      </Col>
                      <Col md={2} sm={12} className='mt-1'>
                        <Button className='ml-2 btn add-btn' type='submit'>
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={4} className='mt-3'>
              <Card>
                <CardBody>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Business Line</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessLineList.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td
                              onClick={() =>
                                showProductLineSection(data.id, data.name)
                              }
                            >
                              {isEdit === index ? (
                                <form
                                  className='d-flex'
                                  onSubmit={e =>
                                    handleEditBusinessLine(e, data.id)
                                  }
                                >
                                  <input
                                    className='form-control'
                                    value={editedBlName}
                                    onChange={e =>
                                      setEditedBlName(e.target.value)
                                    }
                                  />
                                  <button
                                    type='submit'
                                    className='ml-1 btn add-btn text-white'
                                  >
                                    Edit
                                  </button>
                                </form>
                              ) : (
                                <Link className='text-dark no-underline'>
                                  {data.name}
                                </Link>
                              )}
                            </td>

                            <td>
                              <FontAwesomeIcon
                                className='text-warning'
                                onClick={() =>
                                  setIsEdit(isEdit === null ? index : null)
                                }
                                icon={faPenToSquare}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
            {showProductLine === true ? (
              <Col md={8} className='mt-3'>
                <Card>
                  <CardBody>
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Product Line</th>
                          <th>Business Line</th>
                          <th>Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productLineList.map((data, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                {isEdit === index ? (
                                  <form
                                    className='d-flex'
                                    onSubmit={e =>
                                      handleEditProductLine(e, data.id)
                                    }
                                  >
                                    <input
                                      className='form-control'
                                      value={editedPlName}
                                      onChange={e =>
                                        setEditedPlName(e.target.value)
                                      }
                                    />
                                    <button
                                      type='submit'
                                      className='ml-1 btn add-btn text-white'
                                    >
                                      Edit
                                    </button>
                                  </form>
                                ) : (
                                  <Link
                                    onClick={() =>
                                      redirectToModel(
                                        data?.id,
                                        data?.businessLine?._id
                                      )
                                    }
                                    className='text-dark no-underline'
                                  >
                                    {data?.productLineName}
                                  </Link>
                                )}
                              </td>
                              <td>{data?.businessLine?.name}</td>
                              <td>
                                <FontAwesomeIcon
                                  className='text-warning'
                                  icon={faPenToSquare}
                                  onClick={() =>
                                    setIsEdit(isEdit === null ? index : null)
                                  }
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ''
            )}
          </Row>
          <Modal show={showSuccessModal} centered>
            <Modal.Body>
              <div class='my-8 text-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-14 shrink-0 fill-green-500 inline'
                  viewBox='0 0 512 512'
                >
                  <path
                    d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                    data-original='#000000'
                  />
                  <path
                    d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                    data-original='#000000'
                  />
                </svg>
                <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                  Job Done
                </h4>
                <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                  {modalMessage} Added Successfully!
                </p>
              </div>

              <button
                type='button'
                onClick={handleCloseModal}
                className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
              >
                Okay
              </button>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </>
  )
}

export default BusinessLine
