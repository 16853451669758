import React, {Fragment} from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';

const LeadConversionRate = () => {
    const series = [{
        name: "Desktops",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }];
    var options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        markers: {
            size: 4,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        colors: ['#2E93fA'],
        title: {
            align: 'left',
            style: {
                fontWeight: 500,
            },
        },

        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    };
    return (
        <Fragment>
            <Card>
                <CardHeader>Lead Conversion Rate</CardHeader>
                <CardBody>
                    <ReactApexChart
                        dir="ltr"
                        options={options}
                        series={series}
                        type="line"
                        height="350"
                        className="apex-charts"
                    />
                </CardBody>
            </Card>

        </Fragment>
    )
}

export default LeadConversionRate