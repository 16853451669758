import { createSlice } from "@reduxjs/toolkit";


const userSlice = createSlice({
    name:"user",
    initialState : {},
    reducers:{
        loginSuccess(state, action){
            return action.payload
        },
        removeUser(state, action){
            return null
        }
    }
})

export const {loginSuccess, removeUser}=userSlice.actions;
export default userSlice.reducer;
