const IndustryList = [
    "Select",
    "Food",
    "Hotel & Commercial Complex",
    "Automobile",
    "Textile",
    "Cement",
    "Chemical",
    "Refineries & Petrochemicals",
    "Power Generation",
    "Paper & Pulp",
    "Pharmaceutical",
    "Distilleries",
    "Sugar",
    "Paint",
    "Edible Oil",
    "Steel",
    "Railway",
    "Rural Water Treatment",
    "Hospitals"
];

export default IndustryList;
