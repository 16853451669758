import React, { Fragment, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { API_URL } from './utils/Constants/URLS'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import PageHeader from './utils/PageHeader'

const Model = () => {
  const token = localStorage.getItem('token')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [storeIdForDelete, setStoreIdForDelete] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [modelName, setModelName] = useState('')
  const [editedModelName, setEditedModelName] = useState('')
  const [editedHSNNumber, setEditedHSNNumber] = useState('')
  const [HSNNumber, setHSNNumber] = useState('')
  const [models, setModels] = useState([])
  const [businessLineList, setBusinessLineList] = useState([])
  const [productLineList, setProductLineList] = useState([])
  const [isEdit, setIsEdit] = useState(null)
  const { businessLineId, productLineId } = useParams()

  const navigate=useNavigate();

  const handleCloseModal = () => {
    setShowSuccessModal(false)
    setShowDeclineModal(false)
  }

  const handleAddModels = async e => {
    e.preventDefault()

    try {
      const url = `${API_URL}models`
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          modelName,
          HSNNumber,
          businessLine: businessLineId,
          productLine: productLineId
        })
      })
      const result = await res.json()
      if (result.error === false) {
        setModalMessage('Model Added Successfully')
        setShowSuccessModal(true)
      } else {
        setShowDeclineModal(true)
      }
    } catch (e) {
      setShowDeclineModal(true)
    }
  }

  useEffect(() => {
    const fetchBusinessLines = async () => {
      try {
        const url = `${API_URL}business-lines?pagination=false`
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        const result = await res.json()
        setBusinessLineList(result.data.docs)
      } catch (error) {
        console.error(error)
      }
    }

    fetchBusinessLines()
  }, [token])

  const handleEditModel = async (e, id) => {
    e.preventDefault()
    const url = `${API_URL}models/${id}`
    try {
      const res = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          modelName: editedModelName,
          HSNNumber:editedHSNNumber,
          businessLine: businessLineId,
          productLine: productLineId
        })
      })
      const result = await res.json()
      console.log(result)

      if (result.error === false) {
        setModalMessage('Model Updated Successfully')
        setShowSuccessModal(true)
      } else {
        setShowDeclineModal(true)
      }
    } catch (e) {
      setShowDeclineModal(true)
    }
  }

  const manageDeleteModal = async id => {
    setStoreIdForDelete(id)
    setShowDeleteModal(true)
  }

  const handleDeleteModel = async id => {
    try {
      const res = await fetch(`${API_URL}models/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`, // Include authorization if required
          'Content-type': 'application/json' // Optional, based on API requirements
        }
      })
      const result = await res.json()
      if (result.error === false) {
        setShowDeleteModal(false)
        setModalMessage('Model Deleted Successfully')
        setShowSuccessModal(true)
      } else {
        const errorResponse = await res.text()
        alert('Failed to delete: ' + errorResponse)
      }
    } catch (error) {
      console.error('Error during deletion:', error)
      alert('An error occurred while trying to delete the model.')
    }
  }

  useEffect(() => {
    const fetchProductLine = async () => {
      try {
        const url = `${API_URL}product-lines?pagination=false`
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        const result = await res.json()
        setProductLineList(result.data.docs)
      } catch (error) {
        console.error(error)
      }
    }

    fetchProductLine()
  }, [token])

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const url = `${API_URL}/models?pagination=false&productLine=${productLineId}`
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        const result = await res.json()
        setModels(result.data.docs)
      } catch (error) {
        console.error(error)
      }
    }

    fetchModels()
  }, [token])

  return (
    <Fragment>
      <PageHeader
        pageName='ORGANISATION CONTACT'
        breadcrump1='Home'
        breadcrump2='Models'
        link='/dashboard'
      />
      <div className='p-4'>
        <Card className=''>
          <CardBody>
            <form onSubmit={handleAddModels}>
              <Row>
                <Col md={2}>
                  <input
                    type='text'
                    className='form-control mb-2'
                    value={businessLineList
                      .filter(item => item.id === businessLineId)
                      .map(item => item.name)
                      .toString()}
                    readOnly
                  />
                </Col>
                <Col md={2}>
                  <input
                    type='text'
                    className='form-control mb-2'
                    value={productLineList
                      .filter(item => item.id === productLineId)
                      .map(item => item.productLineName)
                      .toString()}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <input
                    className='form-control mb-2'
                    value={modelName}
                    onChange={e => setModelName(e.target.value)}
                    placeholder='Enter Model Name'
                    required
                  />
                </Col>
                <Col md={3}>
                  <input
                    className='form-control mb-2'
                    value={HSNNumber}
                    onChange={e => setHSNNumber(e.target.value)}
                    placeholder='Enter HSN Number'
                    required
                  />
                </Col>
                <Col md={2}>
                  <button type='submit' className='btn add-btn text-white'>
                    Add
                  </button>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>

        <Card className='mt-4'>
          <CardBody>
            <Row>
              {models.map((data, index) => {
                return (
                  <Col md={4} key={index} className='mt-3'>
                    <Card className='shadow-md'>
                      <CardBody>
                        <div className='d-flex justify-content-between'>
                          <h5>{data.productLine.productLineName}</h5>
                          <h4 className='text-sm text-gray-500'>
                            {data.HSNNumber}
                          </h4>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                          <div>
                            {isEdit === index ? (
                              <form
                                className='d-flex'
                                onSubmit={e => handleEditModel(e, data.id)}
                              >
                                <Row>
                                  <Col md={12}>
                                    <input
                                      value={editedModelName}
                                      onChange={e =>
                                        setEditedModelName(e.target.value)
                                      }
                                      className='form-control'
                                    />
                                  </Col>
                                  <Col md={12} className='mt-2'>
                                    <input
                                      value={editedHSNNumber}
                                      onChange={e =>
                                        setEditedHSNNumber(e.target.value)
                                      }
                                      className='form-control'
                                    />
                                  </Col>
                                  <Col md={12} className='mt-2'>
                                    <button
                                      type='submit'
                                      className='btn add-btn text-white ml-1'
                                    >
                                      Edit
                                    </button>
                                  </Col>
                                </Row>
                              </form>
                            ) : (
                              <Fragment>
                                <h2>{data.modelName}</h2>
                              </Fragment>
                            )}
                          </div>
                          <div className='mt-2'>
                            <Link>
                              <FontAwesomeIcon
                                className='text-warning mr-2'
                                onClick={() =>
                                  setIsEdit(isEdit === null ? index : null)
                                }
                                icon={faPenToSquare}
                              />
                            </Link>
                            <Link>
                              <FontAwesomeIcon
                                className='text-gray-500'
                                onClick={() => manageDeleteModal(data.id)}
                                icon={faTrash}
                              />
                            </Link>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </CardBody>
          <Modal show={showSuccessModal} centered>
            <Modal.Body>
              <div class='my-8 text-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className={`w-14 shrink-0 ${
                    modalMessage === 'Model Deleted Successfully'
                      ? 'fill-blue-500'
                      : 'fill-green-500'
                  }  inline`}
                  viewBox='0 0 512 512'
                >
                  <path
                    d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                    data-original='#000000'
                  />
                  <path
                    d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                    data-original='#000000'
                  />
                </svg>
                <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                  Job Done
                </h4>
                <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                  {modalMessage}!
                </p>
              </div>

              <button
                type='button'
                onClick={handleCloseModal}
                className={`px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none ${
                  modalMessage === 'Model Deleted Successfully'
                    ? 'bg-blue-500 hover:bg-blue-700'
                    : 'bg-green-500 hover:bg-green-700'
                } `}
              >
                Okay
              </button>
            </Modal.Body>
          </Modal>
          <Modal show={showDeclineModal} centered>
            <Modal.Body>
              <div class='my-8 text-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-14 fill-red-500 inline'
                  viewBox='0 0 286.054 286.054'
                >
                  <path
                    fill='#e2574c'
                    d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                    data-original='#e2574c'
                  ></path>
                </svg>
                <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                  Error Occured
                </h4>
                <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                  Something went wrong!
                </p>
              </div>

              <button
                type='button'
                onClick={handleCloseModal}
                className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
              >
                Okay
              </button>
            </Modal.Body>
          </Modal>
          <Modal show={showDeleteModal} centered>
            <Modal.Body>
              <div className='my-4 text-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-14 fill-red-500 inline'
                  viewBox='0 0 24 24'
                >
                  <path
                    d='M19 7a1 1 0 0 0-1 1v11.191A1.92 1.92 0 0 1 15.99 21H8.01A1.92 1.92 0 0 1 6 19.191V8a1 1 0 0 0-2 0v11.191A3.918 3.918 0 0 0 8.01 23h7.98A3.918 3.918 0 0 0 20 19.191V8a1 1 0 0 0-1-1Zm1-3h-4V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM10 4V3h4v1Z'
                    data-original='#000000'
                  />
                  <path
                    d='M11 17v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Zm4 0v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Z'
                    data-original='#000000'
                  />
                </svg>
                <h4 className='text-gray-800 text-base font-semibold mt-4'>
                  Are you sure you want to delete it?
                </h4>

                <div className='text-center space-x-4 mt-8'>
                  <button
                    onClick={() => setShowDeleteModal(false)}
                    type='button'
                    className='px-4 py-2 rounded-lg text-gray-800 text-sm bg-gray-200 hover:bg-gray-300 active:bg-gray-200'
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleDeleteModel(storeIdForDelete)}
                    type='button'
                    className='px-4 py-2 rounded-lg text-white text-sm bg-red-600 hover:bg-red-700 active:bg-red-600'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Card>
      </div>
    </Fragment>
  )
}

export default Model
