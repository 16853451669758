import React, { Fragment } from 'react'
import { Card, Row, Col } from 'reactstrap';
import DealVelocity from './DashboardComponent/DealVelocity.js';
import PipeLineByStage from './DashboardComponent/PipeLineByStage.js';
import NewVsReturningCustomer from './DashboardComponent/NewVsReturningCustomer.js';
import MonthlyRevenueGrowth from './DashboardComponent/MonthlyRevenueGrowth.js';
import LeadConversionRate from './DashboardComponent/LeadConversionRate.js';
import LeadResponseTime from './DashboardComponent/LeadResponseTime.js';
import CLVOverTime from './DashboardComponent/CLVOverTime.js';
import CLVOverBusinessLine from './DashboardComponent/CLVOverBusinessLine.js';
import ViewPipeline from './DashboardComponent/ViewPipeline.js';
import PageHeader from './utils/PageHeader.js';
const Dashboard = () => {
    // const token = localStorage.getItem('token');

    // useEffect(() => {
    //     getUserProfile()
    // })

    // const getUserProfile = async () => {
    //     const url = `http://localhost:8000/api/users/${userId}`;

    //     const data = await fetch(url, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${token}`
    //         }
    //     })

    //     const result = await data.json();
    //     dispatch(storeUsername(result.data.name))
    // }

    return (
        <Fragment>
            <PageHeader
                pageName="DASHBOARD"
                breadcrump1="Home"
                breadcrump2="Dashboard"
                link="/dashboard"
            />
            <div className='ml-5 mt-2 p-2'>
                <Row>
                    <Col md={4} className='mt-2'>
                        <Card>
                            <div className="row row-cols-1">
                                <div className="col">
                                    <div className="py-4 px-3">
                                        <p className="text-muted text-uppercase fs-13 text-center">Active Leads</p>
                                        <div className="d-flex align-items-center">
                                            <div className="p-2 flex-shrink-0 bg-[#a0bffd]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-briefcase text-primary"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                                            </div>
                                            <div className="flex-grow-1 ms-3">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4} className='mt-2'>
                        <Card>
                            <div className="row row-cols-1">
                                <div className="col">
                                    <div className="py-4 px-3">
                                        <p className="text-muted text-uppercase fs-13 text-center">Deal Won</p>
                                        <div className="d-flex align-items-center">
                                            <div className="p-2 flex-shrink-0 bg-[#faf9b6]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award text-warning"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                                            </div>
                                            <div className="flex-grow-1 ms-3">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4} className='mt-2'>
                        <Card>
                            <div className="row row-cols-1">
                                <div className="col">
                                    <div className="py-4 px-3">
                                        <p className="text-muted text-uppercase fs-13 text-center">All Projects</p>
                                        <div className="d-flex align-items-center">
                                            <div className="p-2 flex-shrink-0 bg-[#bcfdff]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(72 195 233)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-projects text-warning">
                                                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                                    <path d="M9 3v18"></path>
                                                    <path d="M15 3v18"></path>
                                                    <path d="M3 9h18"></path>
                                                    <path d="M3 15h18"></path>
                                                </svg>

                                            </div>
                                            <div className="flex-grow-1 ms-3">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className='mt-3 ml-5'>
                <ViewPipeline />
            </div>

            <div className="mt-3 ml-5">
                <Row>
                    <Col md={6}>
                        <DealVelocity />
                    </Col>
                    <Col md={6}>
                        <PipeLineByStage />
                    </Col>
                    <Col md={6} className='mt-3'>
                        <NewVsReturningCustomer />
                    </Col>
                    <Col md={6} className='mt-3'>
                        <MonthlyRevenueGrowth />
                    </Col>
                    <Col md={6} className='mt-3'>
                        <LeadConversionRate />
                    </Col>
                    <Col md={6} className='mt-3'>
                        <LeadResponseTime />
                    </Col>
                    <Col md={6} className='mt-3'>
                        <CLVOverTime />
                    </Col>

                    <Col md={6} className='mt-3'>
                        <CLVOverBusinessLine />
                    </Col>
                </Row>
            </div>
        </Fragment >
    )
}

export default Dashboard