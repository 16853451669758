import React, { Fragment } from 'react'
import ReactApexChart from 'react-apexcharts'
import { CardBody, CardHeader, Card } from 'reactstrap';
const CLVOverBusinessLine = () => {
    var options = {
        series: [{
            data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
        }],
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                borderRadiusApplication: 'end',
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
                'United States', 'China', 'Germany'
            ],
        }
    };
    return (
        <Fragment>
            <Card>
                <CardHeader>CLV By Business Line</CardHeader>
                <CardBody>
                    <ReactApexChart
                        dir="ltr"
                        options={options}
                        series={options.series}
                        type="bar"
                        height="350"
                        className="apex-charts"
                    />
                </CardBody>
            </Card>

        </Fragment>
    )
}

export default CLVOverBusinessLine