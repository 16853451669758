import { useContext, createContext, useState, Fragment } from 'react'
import '../../css/Sidebar.css'
import Logo from '../../images/greenergy-logo.png'
import Volt from '../../images/greenergy-logo-small.png'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { removeDeal } from '../utils/redux/dealsSlice'
import { removeUser } from '../utils/redux/userSlice'

const SidebarContext = createContext()

export default function SidebarContainer ({ children }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <aside
      className={`fixed left-0 z-40 h-screen  transition-transform transform bg-white border-r border-gray-200 rounded-lg sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700 `}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      <nav className='h-full flex flex-col bg-white border-r shadow-sm'>
        <div className='p-4 pb-2 flex justify-between '>
          <img
            src={Logo}
            className={`overflow-hidden transition-all ${
              expanded ? 'w-48 h-10' : 'w-0'
            }`}
            alt=''
          />

          {expanded === false ? (
            <span className='small-logo ml-0.5'>
              <img className='h-10' src={Volt} alt='' />
            </span>
          ) : (
            ''
          )}
        </div>

        <SidebarContext.Provider value={{ expanded }}>
          <ul className='flex-1 ml-1.5'>{children}</ul>
        </SidebarContext.Provider>
      </nav>
    </aside>
  )
}

export function SidebarItems ({ icon, text, alert, link }) {
  const { expanded } = useContext(SidebarContext)
  const dispatch = useDispatch()

  const handleLogout = () => {
    localStorage.removeItem('token')
    dispatch(removeDeal())
    dispatch(removeUser())
  }

  return (
    <Fragment>
      <li
        className={`sidebar-items
        relative flex items-center py-2  ${expanded ? 'my-4' : 'mt-1'}
        font-medium right-3 rounded-md cursor-pointer
        transition-colors group text-xl
    `}
      >
        {icon}
        <span
          className={`overflow-hidden transition-all ${
            expanded ? 'w-52 ml-3' : 'w-0'
          }`}
        >
          {text === 'Sign Out' ? (
            <Link
              className='sidebar-items no-underline'
              to={link}
              onClick={handleLogout}
            >
              {text}
            </Link>
          ) : (
            <Link className='sidebar-items no-underline' to={link}>
              {text}
            </Link>
          )}
        </span>
        {alert && (
          <div
            className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
              expanded ? '' : 'top-2'
            }`}
          />
        )}

        {!expanded && (
          <div
            className={`
          absolute left-full rounded-md  py-1 ml-6
          bg-indigo-100 text-indigo-800 text-sm
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
      `}
          ></div>
        )}
      </li>
    </Fragment>
  )
}
