import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Container, CardHeader } from 'reactstrap';
import "../../css/AddMember.css";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Multiselect } from 'multiselect-react-dropdown';
import CsvUpload from './CsvUpload';
import { useParams } from 'react-router-dom';

const EditMember = () => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    let [email, setEmail] = useState('');
    const [secPhoneNumber, setSecPhoneNumber] = useState('');
    const [businessArea, setBusinessArea] = useState([]);
    const [businessLineList, setBusinessLineList] = useState([]);
    const [businessLineName, setBusinessLineName] = useState('');
    const [filteredBusinessLines, setFilteredBusinessLines] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [role, setRole] = useState('');
    const token = localStorage.getItem('token');
    const { resid } = useParams();
    const businessAreaData = [
        { id: 1, business_area: "NB" },
        { id: 2, business_area: "NE" },
        { id: 3, business_area: "SB" }
    ];

    const [baOptions] = useState(businessAreaData);

    
    // Debounced search handler
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        // Clear the previous timeout
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        // Set a new timeout for the debounce
        const newTimeout = setTimeout(() => {
            const filtered = businessLineList.filter((bl) =>
                bl.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredBusinessLines(filtered);
            setShowDropdown(filtered.length > 0); // Show dropdown only if there are results
        }, 300); // 300ms delay for debouncing

        setDebounceTimeout(newTimeout);
    };

    const handleSelectBusinessLine = (name) => {
        setBusinessLineName(name);
        setSearchTerm(name); // Set search term to the selected business line
        setShowDropdown(false); // Close dropdown after selecting
    };

    const handleEditResources = async (e) => {
        console.log(resid);
        const businessLine = businessLineList.filter(item => item.name === businessLineName).map(item => item.id).toString();
        email = email.concat("@greenergy.net.in");
        const url = `http://localhost:8000/api/users/${resid}`;
        
        try {
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    name,
                    email,
                    phoneNumber,
                    businessLine,
                    businessArea: businessArea.map(item => item.business_area),
                    role
                })
            });
            const result = await res.json();
            console.log(result);

        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const fetchBusinessLines = async () => {
            const url = "http://localhost:8000/api/business-lines?paginate=false"; //paginate=false
            const data = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            const result = await data.json();
            setBusinessLineList(result.data.docs.map(item => ({
                id: item.id,
                name: item.name
            })));
        };

        fetchBusinessLines();
    }, [token]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Row className="justify-content-center">
                                <Col>
                                    <Card className="mt-4">
                                        <CardHeader className="card-header">
                                            <h4 className="card-title mb-0">Edit Employee</h4>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                            <div>
                                                <form onSubmit={handleEditResources} className="row g-3 needs-validation" noValidate>
                                                    <div className="col-md-4">
                                                        <label htmlFor="name" className="form-label">
                                                            Name <span className='text-danger'>*</span>
                                                        </label>
                                                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" id="name" required />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="phoneno" className="form-label">
                                                            Primary Phone Number <span className='text-danger'>*</span>
                                                        </label>
                                                        <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="number" className="form-control" id="phoneno" required />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="validationCustomUsername" className="form-label">
                                                            Email <span className='text-danger'>*</span>
                                                        </label>
                                                        <div className="input-group has-validation">
                                                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required />
                                                            <span className="input-group-text" id="inputGroupPrepend">@greenergy.net.in</span>
                                                            <div className="invalid-feedback">
                                                                Please choose an email.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="phoneno" className="form-label">
                                                            Secondary Phone Number
                                                        </label>
                                                        <input value={secPhoneNumber} onChange={(e) => setSecPhoneNumber(e.target.value)} type="number" className="form-control" id="phoneno" />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustom03" className="form-label">
                                                            Business Area <span className='text-danger'>*</span>
                                                        </label>
                                                        <Multiselect
                                                            className='multi-select'
                                                            options={baOptions}
                                                            selectedValues={businessArea}
                                                            onSelect={setBusinessArea}
                                                            onRemove={setBusinessArea}
                                                            displayValue='business_area'
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustom04" className="form-label">
                                                            Business Line <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control mb-2"
                                                            placeholder="Search Business Line"
                                                            value={searchTerm}
                                                            onChange={handleSearchChange}
                                                            onFocus={() => setShowDropdown(true)} // Show dropdown on focus
                                                        />
                                                        {showDropdown && (
                                                            <ul className="list-group" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                                {filteredBusinessLines.length > 0 ? (
                                                                    filteredBusinessLines.map((bl, index) => (
                                                                        <li
                                                                            key={index}
                                                                            className="list-group-item list-group-item-action"
                                                                            onClick={() => handleSelectBusinessLine(bl.name)}
                                                                            style={{ cursor: 'pointer' }}
                                                                        >
                                                                            {bl.name}
                                                                        </li>
                                                                    ))
                                                                ) : (
                                                                    <li className="list-group-item">No results found</li>
                                                                )}
                                                            </ul>
                                                        )}
                                                        <div className="invalid-feedback">Please select a valid Business line.</div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustom05" className="form-label">
                                                            Role <span className='text-danger'>*</span>
                                                        </label>
                                                        <select value={role} onChange={(e) => setRole(e.target.value)} className="form-select" id="validationCustom04" required>
                                                            <option>Manager</option>
                                                            <option>Executive</option>
                                                        </select>
                                                        <div className="invalid-feedback">
                                                            Please provide a valid role.
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <button className="btn btn-primary" type="submit">Submit form</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <h5 className='text-center mt-2'>OR</h5>
                            <CsvUpload />
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default EditMember;
