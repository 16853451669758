import React, { Fragment, useState, useEffect } from 'react'
import '../../css/SubProgressBar.css'
import { TabContent, TabPane } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import SearchExistingCompany from '../Forms/FormComponents/SearchExistingCompany'
import ProductRequirement from '../Forms/FormComponents/ProductRequirement'
import EnquiryDetails from '../Forms/FormComponents/EnquiryDetails'
import MarketingPlatform from '../Forms/FormComponents/MarketingPlatform'
import WorkingPlatform from '../Forms/FormComponents/WorkingPlatform'
import BuyingPlatform from '../Forms/FormComponents/BuyingPlatform'
import AddClientForLead from '../Forms/FormComponents/AddClientForLead'

const SubProgessBar = props => {
  const { itemOne, itemTwo, itemThree, id, initialActiveTab } = props
  const [activeButton, setActiveButton] = useState(initialActiveTab)
  const [existingClient, setExistingClient] = useState(false)

  useEffect(() => {
    setActiveButton(initialActiveTab)
  }, [initialActiveTab])

  return (
    <Fragment>
      <div className='d-flex'>
        <button
          type='button'
          className={`sub-progressbar-btn
            ${
              activeButton === 'Client' || activeButton === 'Marketing'
                ? 'active'
                : itemOne === 'Client (Done)' || itemOne === 'Marketing (Done)'
                ? 'done'
                : ''
            }`}
        >
          <span className='text-lg'>{itemOne.split(' ')[0]}</span>
        </button>

        <button
          type='button'
          className={`sub-progressbar-btn ${
            activeButton === 'Product' || activeButton === 'Working'
              ? 'active'
              : itemTwo === 'Product (Done)' || itemTwo === 'Working (Done)'
              ? 'done'
              : ''
          }`}
        >
          <span className='text-lg'>{itemTwo.split(' ')[0]}</span>
        </button>

        <button
          type='button'
          className={`sub-progressbar-btn ${
            activeButton === 'Enquiry' || activeButton === 'Buying'
              ? 'active'
              : ''
          }`}
        >
          <span className='text-lg'>{itemThree.split(' ')[0]}</span>
        </button>
      </div>

      <div className='mt-3'>
        <TabContent activeTab={activeButton}>
          <TabPane tabId={itemOne}>
            {itemOne === 'Client' ? (
              <div>
                <div>
                  <Form>
                    <Form.Check
                      type='switch'
                      id='custom-switch'
                      label='Existing Customer'
                      onChange={e => setExistingClient(e.target.checked)}
                      className='custom-switch-size'
                    />
                  </Form>
                </div>

                <div className='mt-3'>
                  {existingClient ? (
                    <SearchExistingCompany />
                  ) : (
                    <AddClientForLead />
                  )}
                </div>
              </div>
            ) : (
              <MarketingPlatform />
            )}
          </TabPane>
          <TabPane tabId={itemTwo}>
            {itemTwo === 'Product' ? (
              <ProductRequirement id={id} />
            ) : (
              <WorkingPlatform />
            )}
          </TabPane>
          <TabPane tabId={itemThree}>
            {itemThree === 'Enquiry' ? (
              <EnquiryDetails id={id} />
            ) : (
              <BuyingPlatform />
            )}
          </TabPane>
        </TabContent>
      </div>
    </Fragment>
  )
}

export default SubProgessBar
