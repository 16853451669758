import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useState } from 'react'
import '../../../css/AddClient.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API_URL } from '../../utils/Constants/URLS'
import { validate } from '../../utils/validate'
import IndianStates from '../../utils/Constants/IndianStateList'
import Select from 'react-select'
const AddClientForLead = () => {
  const token = localStorage.getItem('token')
  let countAddress = 0
  let countContactPerson = 0
  const navigate = useNavigate()
  const { id } = useParams()
  const [isOpenAddress, setIsOpenAddress] = useState([])
  const [formData, setFormData] = useState({
    companyName: '',
    address: [
      {
        addressNickName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        pincode: ''
      }
    ],
    phoneNumber: '',
    phoneNumberOptional: '',
    contactPersons: [
      {
        name: '',
        email: '',
        phoneNumber: '',
        phoneNumberOptional: '',
        designation: ''
      }
    ],
    organizationType: '',
    isGSTRegistered: false,
    gstNumber: '',
    createdBy: ''
  })

  const handleInputChange = (input, index, field, type) => {
    // Determine if `input` is from a Select component (an object with a `value` property)
    const value =
      input && typeof input === 'object' && input.hasOwnProperty('value')
        ? input.value // Select component provides the `value` property
        : input.target.value // Standard input event

    if (type === 'address') {
      const updatedAddresses = [...formData.address]
      updatedAddresses[index][field] = value
      setFormData({ ...formData, address: updatedAddresses })
    } else if (type === 'contactPersons') {
      const updatedContacts = [...formData.contactPersons]
      updatedContacts[index][field] = value
      setFormData({ ...formData, contactPersons: updatedContacts })
    } else {
      setFormData({ ...formData, [field]: value })
    }
  }

  const handleAddAddress = () => {
    setFormData({
      ...formData,
      address: [
        ...formData.address,
        {
          addressNickName: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: '',
          pincode: ''
        }
      ]
    })
    setIsOpenAddress([...isOpenAddress, false])
  }

  const handleDeleteAddress = indexToDelete => {
    setFormData({
      ...formData,
      address: formData.address.filter((_, index) => index !== indexToDelete)
    })
  }

  const handleAddContactPerson = () => {
    setFormData({
      ...formData,
      contactPersons: [
        ...formData.contactPersons,
        {
          name: '',
          email: '',
          phoneNumber: '',
          phoneNumberOptional: '',
          designation: ''
        }
      ]
    })
  }

  const handleDeleteContactPerson = indexToDelete => {
    setFormData({
      ...formData,
      contactPersons: formData.contactPersons.filter(
        (_, index) => index !== indexToDelete
      )
    })
  }

  const updateLeadForClient = async clientId => {
    try {
      const res = await fetch(`${API_URL}leads/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          client: clientId
        })
      })
      const response = await res.json()
      if (response.error === false) {
        alert('client has been succesfully assign in lead')
        navigate('/view_leads')
      } else {
        console.log('lead not updated')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const message = validate(
      formData.gstNumber,
      formData.phoneNumber,
      formData.phoneNumberOptional
    )
    console.log(message)
    if (message === null) {
      try {
        const response = await fetch(`${API_URL}clients`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(formData)
        })
        const result = await response.json()
        if (result.error === false) {
          updateLeadForClient(result.data.id)
          alert('Client Added Successfully')
        } else {
          alert('Someting Went Wrong')
        }
      } catch (error) {
        console.error('Fetch error:', error)
      }
    } else {
      alert(message)
    }
  }

  return (
    <Fragment>
      <div>
        <form className='row g-3 needs-validation' novalidate>
          <div className='row mt-3 pb-3 border-bottom'>
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Company Name <span className='text-danger'>*</span>
              </label>
              <input
                value={formData.companyName}
                onChange={e => handleInputChange(e, null, 'companyName')}
                type='text'
                className='form-control'
                id='name'
                required
              />
              <div className='invalid-feedback'>
                Please enter the company name
              </div>
            </div>
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Phone <span className='text-danger'>*</span>
              </label>
              <input
                value={formData.phoneNumber}
                onChange={e => handleInputChange(e, null, 'phoneNumber')}
                type='number'
                className='form-control'
                id='name'
                required
              />
              <div className='invalid-feedback'>
                Please enter a valid phone number
              </div>
            </div>
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Alternate Phone
              </label>
              <input
                value={formData.phoneNumberOptional}
                onChange={e =>
                  handleInputChange(e, null, 'phoneNumberOptional')
                }
                type='number'
                className='form-control'
                id='name'
              />
            </div>
            <div className='col-md-4 mt-2'>
              <label for='name' className='form-label'>
                Organization Type <span className='text-danger'>*</span>
              </label>
              <select
                value={formData.organizationType}
                onChange={e => handleInputChange(e, null, 'organizationType')}
                className='form-select custom-select'
              >
                <option>Limited</option>
                <option>Private Limited</option>
                <option>LLP</option>
                <option>Govt</option>
                <option>Public Sector Unit</option>
                <option>UT</option>
                <option>Partnership</option>
                <option>Proprietorship</option>
              </select>
            </div>
            <div className='col-md-4 mt-2'>
              <label for='name' className='form-label'>
                Is GST Registered <span className='text-danger'>*</span>
              </label>
              <select
                value={formData.isGSTRegistered ? 'Yes' : 'No'} // Display 'Yes' or 'No' based on the boolean value
                onChange={e =>
                  handleInputChange(e, null, 'isGSTRegistered', 'boolean')
                } // Use 'boolean' type to differentiate
                className='form-select'
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
            <div className='col-md-4 mt-2'>
              <label for='name' className='form-label'>
                GST Number <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                value={formData.gstNumber}
                onChange={e => handleInputChange(e, null, 'gstNumber')}
              />
            </div>
          </div>
          {formData.address.map((address, index) => (
            <div className='row mt-3' key={index}>
              <div className='d-flex justify-content-between'>
                <span>
                  <h6>
                    Add{' '}
                    {(countAddress = countAddress + 1) === 1
                      ? 'First'
                      : 'Second'}{' '}
                    Address
                  </h6>
                </span>
                {countAddress > 1 ? (
                  <span>
                    <div>
                      <Link className='text-dark no-underline'>
                        <FontAwesomeIcon
                          className='ml-1 text-danger'
                          onClick={() => handleDeleteAddress(index)}
                          icon={faTrashCan}
                        />
                      </Link>
                    </div>
                  </span>
                ) : (
                  ' '
                )}
              </div>

              <div className='col-md-4'>
                <label className='form-label'>
                  Country <span className='text-danger'>*</span>
                </label>
                <select
                  value={address.country}
                  onChange={e =>
                    handleInputChange(e, index, 'country', 'address')
                  }
                  className='form-select'
                >
                  <option>Select</option>
                  <option>India</option>
                  <option>Bangladesh</option>
                </select>
              </div>
              <div className='col-md-4'></div>
              <div className='col-md-4'></div>

              <div className='col-md-4'>
                <label className='form-label'>
                  Address Type <span className='text-danger'>*</span>
                </label>
                <input
                  value={address.addressNickName}
                  onChange={e =>
                    handleInputChange(e, index, 'addressNickName', 'address')
                  }
                  type='text'
                  className='form-control'
                  placeholder='Eg: Head Office'
                />
              </div>
              <div className='col-md-4'>
                <label className='form-label'>
                  Address Line 1 <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={address.addressLine1}
                  onChange={e =>
                    handleInputChange(e, index, 'addressLine1', 'address')
                  }
                />
              </div>
              <div className='col-md-4'>
                <label className='form-label'>Address Line 2</label>
                <input
                  type='text'
                  className='form-control'
                  value={address.addressLine2}
                  onChange={e =>
                    handleInputChange(e, index, 'addressLine2', 'address')
                  }
                />
              </div>

              <div className='col-md-4 mt-2'>
                <label className='form-label'>
                  City <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={address.city}
                  onChange={e => handleInputChange(e, index, 'city', 'address')}
                />
              </div>
              <div className='col-md-4 mt-2'>
                <label className='form-label'>
                  State <span className='text-danger'>*</span>
                </label>
                {formData.address[index].country === 'India' ? (
                  <Select
                    value={IndianStates.find(
                      option => option.value === address.state
                    )}
                    onChange={selectedOption =>
                      handleInputChange(
                        selectedOption,
                        index,
                        'state',
                        'address'
                      )
                    }
                    options={IndianStates}
                  />
                ) : (
                  <input
                    type='text'
                    className='form-control'
                    value={address.state}
                    onChange={e =>
                      handleInputChange(e, index, 'state', 'address')
                    }
                  />
                )}
              </div>
              <div className='col-md-4 mt-2'>
                <label className='form-label'>
                  Pin: <span className='text-danger'>*</span>
                </label>
                <input
                  type='number'
                  className='form-control'
                  value={address.pincode}
                  onChange={e =>
                    handleInputChange(e, index, 'pincode', 'address')
                  }
                />
              </div>
            </div>
          ))}
          <div className='row mt-3'>
            <div className='col-md-4'>
              {countAddress <= 1 ? (
                <button
                  onClick={handleAddAddress}
                  type='button'
                  id='add_address'
                  className='btn add-btn  text-white'
                >
                  Add New Address
                </button>
              ) : (
                ' '
              )}
            </div>
          </div>

          {formData.contactPersons.map((person, index) => (
            <div className='row mt-3' key={index}>
              <div className='d-flex justify-content-between'>
                <span>
                  <h6>
                    Add{' '}
                    {(() => {
                      const ordinals = [
                        'First',
                        'Second',
                        'Third',
                        'Fourth',
                        'Fifth'
                      ]
                      return (
                        ordinals[
                          (countContactPerson = countContactPerson + 1) - 1
                        ] || countAddress
                      )
                    })()}{' '}
                    Contact Person
                  </h6>
                  {countContactPerson > 1 ? (
                    <span>
                      <div>
                        <Link className='text-dark no-underline'>
                          <FontAwesomeIcon
                            className='ml-1 text-danger'
                            onClick={() => handleDeleteContactPerson(index)}
                            icon={faTrashCan}
                          />
                        </Link>
                      </div>
                    </span>
                  ) : (
                    ' '
                  )}
                </span>
              </div>

              <div className='col-md-6'>
                <label className='form-label'>
                  Name <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={person.name}
                  onChange={e =>
                    handleInputChange(e, index, 'name', 'contactPersons')
                  }
                />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  Email <span className='text-danger'>*</span>
                </label>
                <input
                  type='email'
                  className='form-control'
                  value={person.email}
                  onChange={e =>
                    handleInputChange(e, index, 'email', 'contactPersons')
                  }
                />
              </div>
              <div className='col-md-4 mt-3'>
                <label className='form-label'>
                  Phone Number <span className='text-danger'>*</span>
                </label>
                <input
                  type='number'
                  className='form-control'
                  value={person.phoneNumber}
                  onChange={e =>
                    handleInputChange(e, index, 'phoneNumber', 'contactPersons')
                  }
                />
              </div>
              <div className='col-md-4 mt-3'>
                <label className='form-label'>Alternate Phone Number</label>
                <input
                  type='number'
                  className='form-control'
                  value={person.phoneNumberOptional}
                  onChange={e =>
                    handleInputChange(
                      e,
                      index,
                      'phoneNumberOptional',
                      'contactPersons'
                    )
                  }
                />
              </div>
              <div className='col-md-4 mt-3'>
                <label className='form-label'>
                  Designation <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={person.designation}
                  onChange={e =>
                    handleInputChange(e, index, 'designation', 'contactPersons')
                  }
                />
              </div>
            </div>
          ))}
          <div className='row mt-3'>
            <div className='col-md-4'>
              {countContactPerson < 5 ? (
                <button
                  type='button'
                  onClick={handleAddContactPerson}
                  className='btn add-btn text-white'
                >
                  Add New Contact
                </button>
              ) : (
                ' '
              )}
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-12'>
              <button
                className='btn add-btn text-white'
                onClick={handleSubmit}
                type='button'
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  )
}

export default AddClientForLead
