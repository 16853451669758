import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import dealsSlice from "./dealsSlice";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";

const persistConfig = {
    key: "user",
    storage,
};

const persistConfigDeal = {
    key: "deal",
    storage
}


const persistedUserReducer = persistReducer(persistConfig, userSlice);
const persistedDealReducer = persistReducer(persistConfigDeal, dealsSlice);

const appStore = configureStore({
    reducer: {
        user: persistedUserReducer,
        deal: persistedDealReducer,
    }
})

const persistor = persistStore(appStore);

export default appStore;
export {persistor};