import React, { useState } from 'react'
import '../../css/login.css'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loginSuccess } from '../utils/redux/userSlice'
import { Col, Row, Card, CardBody } from 'reactstrap'
import Logo from '../../images/com_logo.png'
import ReCAPTCHA from 'react-google-recaptcha'
import { API_URL } from '../utils/Constants/URLS'

const Login1 = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleUserLogin = async e => {
    e.preventDefault()
    console.log(error)

    try {
      const url = `${API_URL}users/login`
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      })
      const result = await res.json()
      if (result.error === false) {
        console.log(result)
        dispatch(loginSuccess(result.data))
        localStorage.setItem('token', result.data.token)
        navigate('/dashboard')
      } else {
        setError(result.message)
      }
    } catch (e) {
      setError(e)
    }
  }

  const handleReCaptcha = () => {
    setVerifiedCaptcha(true)
  }
  return (
    <div className='login-page'>
      <Card className='mt-4'>
        <CardBody className='p-4'>
          <Row className='logo'>
            <Col
              className='d-flex justify-content-center align-items-center'
              md={12}
            >
              <img src={Logo} alt='CRM Logo' />
            </Col>
          </Row>
          <div className='text-red-600 text-center mt-3'>{error}</div>
          <form onSubmit={handleUserLogin} className='mt-4'>
            <Row className='mb-3'>
              <Col md={12}>
                <label>Email</label>
                <input
                  name='email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className='form-control credentials mt-1'
                  type='email'
                  placeholder='Enter your email'
                  required
                />
              </Col>
            </Row>
            <Row className='mt-3 mb-3'>
              <Col md={12}>
                <label>Password</label>
                <input
                  name='password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className='form-control credentials mt-1'
                  type='password'
                  placeholder='Enter your password'
                  required
                />
              </Col>
            </Row>

            <Row className='mt-3'>
              <Link className='no-underline'>
                <span className='text-orange-400'>Forgot Pasword ?</span>
              </Link>
            </Row>

            <Row className='mt-3'>
              <ReCAPTCHA
                sitekey='6Ld-3VUqAAAAADyVkNcC0nqfM6y9RsLB4P55aUsL'
                onChange={handleReCaptcha}
              />
            </Row>

            {verifiedCaptcha && (
              <Row className='mt-3'>
                <center>
                  <Col md={6} sm={4}>
                    <button
                      className='btn btn-login w-100 justify-content-center align-items-center text-white'
                      type='submit'
                    >
                      Sign In
                    </button>
                  </Col>
                </center>
              </Row>
            )}
          </form>
        </CardBody>
      </Card>
    </div>
  )
}

export default Login1
