import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import BusinessLine from './Components/BusinessLine';
import OrgContact from './Components/OrgContact';
import AddClientContact from './Components/Forms/AddClientContact';
import ClientContact from './Components/ClientContact';
import ClientProfile from './Components/ClientProfile';
import Lead from './Components/Lead';
import EditMember from './Components/Forms/EditMember';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Login1 from './Components/login/Login1';
import ViewLeads from './Components/ViewLeads';
import { Provider } from 'react-redux';
import appStore from './Components/utils/redux/appStore';
import Model from './Components/Model';
import EditClientContact from './Components/Forms/EditClientContact';
import LeadProfile from './Components/LeadProfile';
import ProtectedRoute from './Components/utils/ProtectedRoutes';
import RedirectLoggedIn from './Components/utils/RedirectToLogin';

const appRouter = createBrowserRouter([
  {
    path:"/",
    element: (
      <RedirectLoggedIn element={<Provider store={appStore}><Login1 /></Provider>} />
    )
  },
  {
    path: "/",
    element: <App/>,
    children:[
      {
        path: "/dashboard",
        element: <ProtectedRoute element={<Dashboard/>}/>
      },
      {
        path: "/business_line",
        element: <ProtectedRoute element={<BusinessLine/>}/>
      },
      {
        path: "/org_contact",
        element: <ProtectedRoute element={<OrgContact/>}/>
      },
      {
        path: "/add_client_contact",
        element: <ProtectedRoute element={<AddClientContact/>}/>
      },
      {
        path: "/client_contact",
        element: <ProtectedRoute element={<ClientContact/>}/>
      },
      {
        path: "/client_profile/:resid",
        element: <ProtectedRoute element={<ClientProfile/>}/>
      },
      {
        path: "/client_profile/:resid/lead/:resid",
        element: <ProtectedRoute element={<Lead condition={true} leadType="new_lead" tabValue="capture_details"/>}/>
      },
      {
        path: "/lead",
        element: <ProtectedRoute element={<Lead condition={true} leadType="new_lead" tabValue="capture_details"/>}/>
      },
      {
        path: "/leads/:id",
        element: <ProtectedRoute element={<Lead condition={false} leadType="existing_lead"/>}/>
      },
      {
        path: "/view_leads",
        element: <ProtectedRoute element={<ViewLeads/>}/>
      },
      {
        path: "/org_contact/edit_resources/:resid",
        element: <ProtectedRoute element={<EditMember/>}/>
      },
      {
        path: "/client_contact/edit_client/:resid",
        element: <ProtectedRoute element={<EditClientContact/>}/>
      },
      {
        path: "/model/:productLineId/:businessLineId",
        element: <ProtectedRoute element={<Model/>}/>
      },
      {
        path: "/client_profile/:resid/lead_details/:leadId",
        element: <ProtectedRoute element={<LeadProfile/>}/>
      },
      {
        path: "/lead_details/:leadId",
        element: <ProtectedRoute element={<LeadProfile/>}/>
      }
    ]
  },
  
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={appRouter} />);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
