import './App.css';
import { Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Components/Navbar.js';
import Sidebar from './Components/Sidebar/Sidebar.js';
import { Provider } from 'react-redux';
import appStore, { persistor } from './Components/utils/redux/appStore.js';
import { PersistGate } from 'redux-persist/integration/react';
import SidebarMobile from './Components/SidebarMobile.js';

function App() {
  return (
    <Provider store={appStore}>
      <PersistGate loading={null} persistor={persistor}>
        <div className='wrapper flex'>
          <div className="block md:hidden sm:hidden"><SidebarMobile /></div>
          <div className="hidden md:block sm:block"><Sidebar /></div>
          <div className='main sm:ml-16 md:ml-16 lg:ml-16 xl:ml-16 2xl:ml-16 mt-16 w-full'>
            <Navbar />
            <Outlet />
          </div>

        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
