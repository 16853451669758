import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const PageHeader = ({ pageName, breadcrump1, breadcrump2, link }) => {
    return (
        <Fragment>
            <div className="page-header">
                <div className="page-title flex flex-col md:flex-row md:items-center md:justify-between px-4  md:px-2 shadow-sm bg-white">
                    {/* Page Title */}
                    <h4 className="text-sm font-semibold text-gray-600 mb-1 mt-2 md:mb-0">
                        {pageName}
                    </h4>

                    {/* Breadcrumb */}
                    <ul className="breadcrumb flex flex-wrap mt-4 text-sm space-x-2">
                        <li className="breadcrumb-item">
                            <Link className="text-dark no-underline" to="/dashboard">
                                {breadcrump1}
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link
                                className="text-indigo-600 no-underline"
                                to="#"
                            >
                                {breadcrump2}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>


    )
}

export default PageHeader