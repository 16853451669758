export const validate = (gst, phone, phoneOptional, pin) => {
    //const isValidEmail = /^[a-zA-Z0-9._%+-]+$/.test(email);
    const isValidGst = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gst);
    const mobileNumber = /^(?:[6-9]\d{9})$/.test(phone);
    const mobileNumberOptional = /^(?:[6-9]\d{9})$/.test(phoneOptional);


    if(!isValidGst) return "GSTIN is not valid";
    if(!mobileNumber) return "Mobile Number is not valid";
    if(!mobileNumberOptional) return "Alternate Mobile Number is not valid";


    return null
}