import React, {useState} from 'react'
import {  registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import {  Card, CardBody,  Col, Row, CardHeader } from 'reactstrap';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const CsvUpload = () => {
    const [selectedFiles, setselectedFiles] = useState([]);

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
    return (
        <>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <Row className="justify-content-center mt-2">
                        <Col>
                            <Card>
                                <CardHeader className="card-header">
                                    <h4 className="card-title mb-0">Upload CSV to Register Employee</h4>
                                </CardHeader>
                                <CardBody>

                                    <p className="text-muted">Upload File of Type csv only</p>
                                    <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles);
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="csv-upload dz-clickable text-center">
                                                <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                >
                                                    <div className="mb-3">
                                                        <FontAwesomeIcon className='upload-icon' icon={faCloudArrowUp} />
                                                    </div>
                                                    <h4>Drop files here or click to upload.</h4>
                                                </div>

                                            </div>

                                        )}
                                    </Dropzone>
                                    <div className="list-unstyled mb-0" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                            return (
                                                <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + "-file"}
                                                >
                                                    <div className="p-2">
                                                        <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                                <img
                                                                    data-dz-thumbnail=""
                                                                    height="80"
                                                                    className="avatar-sm rounded bg-light"
                                                                    alt={f.name}
                                                                    src={f.preview}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Link
                                                                    to="#"
                                                                    className="text-muted font-weight-bold"
                                                                >
                                                                    {f.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                    <strong>{f.formattedSize}</strong>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default CsvUpload