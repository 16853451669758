import React, { Fragment } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Card, CardBody, CardHeader } from 'reactstrap';

const NewVsReturningCustomer = () => {
    const series = [44, 55, 13, 43, 22]
    var options = {
        chart: {
            height: 300,
            type: 'pie',
        },
        labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        legend: {
            position: 'bottom'
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        colors: ["#ff1a1a", "#00e673", "#ffff00", "#ff00ff", "#00ccff"],
    };
    return (
        <Fragment>
            <Card>
                <CardHeader>New V/S Returning Customer</CardHeader>
                <CardBody>
                    <ReactApexChart
                        dir="ltr"
                        className="apex-charts"
                        series={series}
                        options={options}
                        type="pie"
                        height={300}
                    />
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default NewVsReturningCustomer