import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Col, Row, Table, CardBody, Card } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom'
import { API_URL } from './utils/Constants/URLS'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { Toast } from 'primereact/toast'
import { useDispatch, useSelector } from 'react-redux'
import '../css/OrgContacts.css'
import '../css/common.css'
import { addDeals } from './utils/redux/dealsSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import PageHeader from './utils/PageHeader'
const ViewLeads = () => {
  const token = localStorage.getItem('token')
  const toast = useRef(null)
  const [isActionOpen, setIsActionOpen] = useState(false)
  const [leadList, setLeadList] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const leadDetails = useSelector(store => store.deals)
  const userDetails = useSelector(store => store.user)
  const toggleAction = () => {
    setIsActionOpen(!isActionOpen)
  }

  const addDealToRedux = deal => {
    dispatch(addDeals(deal))
  }

  const showToastMessage = () => {
    toast.current.show({
      severity: 'info',
      summary: 'Info',
      detail: 'The Lead Journey Has Ended',
      life: 3000
    })
  }
  useEffect(() => {
    const fetchLead = async () => {
      const url = `${API_URL}leads?pagination=false`
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/Json',
          Authorization: `Bearer ${token}`
        }
      })
      const response = await res.json()
      setLeadList(response.data.docs)
    }

    fetchLead()
  }, [token])

  return (
    <Fragment>
      <div className='w-100'>
        <PageHeader
          pageName='VIEW LEADS'
          breadcrump1='Home'
          breadcrump2='View Leads'
          link='/dashboard'
        />
        <div className='main lg:p-6 xl:p-6 2xl:p-6 sm:p-3 md:p-3 '>
          <CardBody className='bg-white p-3'>
            <form>
              <Row>
                <Col lg={3} md={3} sm={6} xs={6}>
                  <div>
                    <input
                      className='form-control'
                      type='text'
                      // onChange={(e) => setSearchValue(e.target.value)}
                      placeholder='Search Here'
                    />
                  </div>
                  {/* <div className="search-box lg:ml-2 xl:ml-2 2xl:ml-2 mb-2 d-inline-block">
                      <select type="text" className="form-select">
                        <option><FontAwesomeIcon icon={faFilter} /> Business Line</option>
                        <option>Grundfos</option>
                        <option>Fowler Westrup</option>
                        <option>Thermax</option>
                      </select>
                    </div> */}
                </Col>
                <Col
                  lg={9}
                  md={9}
                  sm={6}
                  xs={6}
                  className='flex-wrap d-none d-md-block'
                >
                  <div className='float-right'>
                    <Link to='/lead'>
                      <button
                        type='button'
                        className='btn add-btn text-white mr-2'
                      >
                        +Add Lead
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
          <CardBody>
            <div className='table-responsive w-full  mb-3 '>
              <Table hover className='align-middle table-nowrap'>
                <thead className='table-light'>
                  <tr>
                    <th>
                      <div className='flex items-center'>Opportunity ID</div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Name
                        <svg
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Value
                        <svg
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Business Line
                        <svg
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Expt Closure
                        <svg
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Owner
                        <svg
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th>
                      <div className='flex items-center'>
                        Status
                        <svg
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th colSpan='2'>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {leadList.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {userDetails.role === 'manager' ||
                          userDetails.role === 'executive' ? (
                            <Link
                              to={
                                data.agreement.stage === 'lost' ||
                                data.agreement.stage === 'won' ||
                                data.agreement.stage === 'drop'
                                  ? `/lead_details/${data.id}`
                                  : `/leads/${data.id}`
                              }
                              className='text-dark no-underline'
                              onClick={() => addDealToRedux(data)}
                            >
                              {data.leadId}
                            </Link>
                          ) : (
                            <Fragment>
                              <span>{data.leadId}</span>
                            </Fragment>
                          )}
                        </td>
                        <td>{data?.leadJourneyName}</td>
                        <td>{data?.productRequirements?.proposedDealAmount}</td>
                        <td>{data?.businessLine?.name}</td>
                        <td>{data?.enquiryDetails?.tentativeClosureDate?.substring(0, 10)}</td>
                        <td>{data?.leadOwner?.name}</td>
                        <td>{data?.agreement?.stage || 'In Progress'}</td>
                        <td>
                          <span className='action-column'>
                            <div className='actions'>
                              {isActionOpen && (
                                <Card>
                                  <CardBody className='w-40'>
                                    <p>
                                      <FontAwesomeIcon icon={faPlus} /> Assign
                                      Member
                                    </p>
                                    {/* <p ><FontAwesomeIcon icon={faTrashCan} /> Delete Address</p> */}
                                  </CardBody>
                                </Card>
                              )}
                            </div>
                            <div>
                              <Link to='#' className='text-dark no-underline'>
                                <FontAwesomeIcon
                                  className='ml-1'
                                  onClick={toggleAction}
                                  icon={faPlus}
                                />
                              </Link>
                            </div>
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardBody className='float-right'>
            <nav className='ml-5' aria-label='...'>
              <ul className='pagination'>
                <li className='page-item disabled'>
                  <Link className='page-link text-primary' to='#' tabindex='-1'>
                    Previous
                  </Link>
                </li>
                {[...Array(totalPages)].map((_, index) => {
                  return (
                    <li className='page-item'>
                      <Link
                        onClick={() => setPageNumber(index + 1)}
                        className='page-link text-primary'
                        to='#'
                      >
                        {index + 1}
                      </Link>
                    </li>
                  )
                })}
                <li className='page-item'>
                  <Link className='page-link text-primary' to='#'>
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </CardBody>
        </div>
        <Toast ref={toast} />
      </div>
    </Fragment>
  )
}

export default ViewLeads
