import { createSlice } from "@reduxjs/toolkit";

const dealSlice = createSlice({
    name:"deals",
    initialState: {},
    reducers:{
        addDeals(state, action){
            return action.payload
        },
        removeDeal(state, action){
            return null;
        }
    }
})

export const {addDeals, removeDeal} = dealSlice.actions;

export default dealSlice.reducer;