import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import EditClient from './FormComponents/EditClient';

const EditClientContact = () => {
    

    return (
        <React.Fragment>
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader className="card-header">
                                        <h4 className="card-title mb-0">Edit Client</h4>
                                    </CardHeader>
                                    <CardBody className='p-4'>
                                        <EditClient/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>

    );
};

export default EditClientContact;
